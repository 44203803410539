import {
  DEFAULT_END_TIME,
  DEFAULT_HOUR_ADDITION,
  DEFAULT_START_TIME,
} from 'constants/searchDefaultHours';
import { dateService } from 'services/DateService';

export function getSearchDefaultHours(date: Date | null) {
  const today = new Date();
  if (date && dateService.isSameDate(today, date) && today.getHours() < 23) {
    const startHour = today.getHours() + 1;
    return {
      startHour,
      endHour: addEndOffset(startHour),
      addEndOffset: addEndOffset,
    };
  } else {
    return {
      startHour: DEFAULT_START_TIME,
      endHour: DEFAULT_END_TIME,
      addEndOffset: addEndOffset,
    };
  }
}

const addEndOffset = (startHour: number) => {
  return (startHour + DEFAULT_HOUR_ADDITION) % 24;
};
