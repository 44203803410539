import Modal from 'components/common/Modal';
import { AuthModalMode } from 'enums/authModalMode';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent, useState } from 'react';
import { authService } from 'services/AuthService';
import { useAuthModalState } from 'states/useAuthModalState';
import { useUserState } from 'states/useUserState';
import { AuthModalIndividualProps, UserInfo } from 'types/auth.type';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import PhoneNumberForm from './PhoneNumberForm';
import Register from './Register';

interface AuthModalWrapperProps {
  modalClass?: string;
}

const AuthModalWrapper: FunctionComponent<AuthModalWrapperProps> = ({
  modalClass,
}) => {
  const { t } = useTranslation();
  const { authMode, setAuthMode, showAuthModal, setShowAuthModal } =
    useAuthModalState();
  const { isUserLoggedIn, setIsUserLoggedIn } = useUserState();

  const title: Record<AuthModalMode, string> = {
    [AuthModalMode.LOGIN]: t('navbar:login'),
    [AuthModalMode.REGISTER]: t('navbar:register'),
    [AuthModalMode.FORGOT_PASSWORD]: t('navbar:forgot-password'),
    [AuthModalMode.PHONE_NUMBER_FORM]: t('navbar:phone-number-form'),
  };

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  const onModeChange: AuthModalIndividualProps['onModeChange'] = ({
    mode,
    email,
    password,
    phoneNumber,
    userInfo,
  }) => {
    setAuthMode(mode);
    email && setEmail(email);
    password && setPassword(password);
    phoneNumber && setPhoneNumber(phoneNumber);
    userInfo && setUserInfo(userInfo);
  };

  const removeTokenOnPhoneReject = () => {
    if (authMode === AuthModalMode.PHONE_NUMBER_FORM) {
      authService.logout();
      setIsUserLoggedIn();
    }
  };

  const onModalClose = () => {
    removeTokenOnPhoneReject();
    setShowAuthModal(false);
  };

  return isUserLoggedIn ? null : (
    <Modal
      show={showAuthModal}
      setShow={onModalClose}
      modalTitle={title[authMode]}
      modalClass={modalClass}
    >
      <div key={authMode}>
        {authMode === AuthModalMode.LOGIN ? (
          <Login
            onModeChange={onModeChange}
            email={email}
            password={password}
          />
        ) : authMode === AuthModalMode.REGISTER ? (
          <Register
            onModeChange={onModeChange}
            email={email}
            password={password}
            phoneNumber={phoneNumber}
          />
        ) : authMode === AuthModalMode.FORGOT_PASSWORD ? (
          <ForgotPassword
            onModeChange={onModeChange}
            email={email}
            password={password}
            phoneNumber={phoneNumber}
          />
        ) : authMode === AuthModalMode.PHONE_NUMBER_FORM ? (
          <PhoneNumberForm
            onModeChange={onModeChange}
            phoneNumber={phoneNumber}
            userInfo={userInfo}
          />
        ) : null}
      </div>
    </Modal>
  );
};

export default AuthModalWrapper;
