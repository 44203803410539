import BButton from 'components/common/BButton';
import BLabeledInput from 'components/Form/BLabeledInput';
import { AuthModalMode } from 'enums/authModalMode';
import { useForm } from 'hooks/useForm';
import useTranslation from 'next-translate/useTranslation';
import { FormEvent, FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import { analyticsService } from 'services/AnalyticsService';
import { authService } from 'services/AuthService';
import { useUserState } from 'states/useUserState';
import {
  AuthModalIndividualProps,
  LoginRequest,
  OnModeChangeParams,
} from 'types/auth.type';
import { emailValidation, validateSchema } from 'validation/auth.validation';
import AuthModalLabelItem from './AuthModalLabelItem';
import BPasswordInput from 'components/Form/BPasswordInput';
import SocialLoginSection from './SocialLoginSection';

const Login: FunctionComponent<AuthModalIndividualProps> = ({
  onModeChange,
  email,
  password,
}) => {
  const { t } = useTranslation();
  const { setIsUserLoggedIn } = useUserState();

  const defaultState: LoginRequest = {
    email: email || '',
    password: password || '',
  };

  const [state, dispatch] = useForm(defaultState);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const schema = {
      email: emailValidation,
      // password: passwordValidation,
    };

    validateSchema(schema, state, t, async () => {
      await authService.login(state);
      analyticsService.login();
      setIsUserLoggedIn();
      toast.success(t('common:auth.login.success'), { toastId: 'login' });
    });
  };

  const onModeChangeWrapped = (mode: OnModeChangeParams['mode']) => {
    onModeChange({
      email: state.email,
      password: state.password,
      mode,
    });
  };

  return (
    <div>
      <form onSubmit={onSubmit} className="space-y-6" noValidate>
        <BLabeledInput
          type="email"
          value={state.email}
          onChange={(e) => dispatch({ type: 'email', payload: e.target.value })}
          label={t('navbar:email')}
        />
        <BPasswordInput
          value={state.password}
          onChange={(e) =>
            dispatch({ type: 'password', payload: e.target.value })
          }
          label={t('navbar:password')}
        />
        <BButton fluid type="submit">
          {t('navbar:signInAction')}
        </BButton>
      </form>
      <AuthModalLabelItem
        labelKey="navbar:signUpLabel"
        className="mt-6"
        actionKey="navbar:signUpAction"
        onClick={() => onModeChangeWrapped(AuthModalMode.REGISTER)}
      />
      <AuthModalLabelItem
        actionKey="navbar:forgotPasswordAction"
        className="mt-1"
        onClick={() => onModeChangeWrapped(AuthModalMode.FORGOT_PASSWORD)}
        labelKey="navbar:forgotPasswordLabel"
      />
      <SocialLoginSection onModeChange={onModeChange} />
    </div>
  );
};

export default Login;
