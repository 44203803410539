import classNames from 'classnames';
import { FunctionComponent, ReactNode } from 'react';

interface DropdownClickableItemProps {
  children: ReactNode;
  onClick?: () => void;
  isActive?: boolean;
  wrapperClass?: string;
}

const DropdownClickableItem: FunctionComponent<DropdownClickableItemProps> = ({
  children,
  onClick,
  isActive,
  wrapperClass,
}) => {
  return (
    <div
      className={classNames(
        wrapperClass,
        'cursor-pointer whitespace-nowrap rounded-lg px-[24px] py-[12px] hover:bg-slate-50',
        {
          'cursor-not-allowed bg-slate-50 text-primary-500': isActive,
        }
      )}
      role="presentation"
      onClick={isActive ? undefined : onClick}
    >
      {children}
    </div>
  );
};

export default DropdownClickableItem;
