import BInput from 'components/Form/BInput';
import { useComponentDidUpdate } from 'hooks/useComponentDidUpdate';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent, useState } from 'react';
import { toast } from 'react-toastify';
import { authService } from 'services/AuthService';
import { useOTPModalState } from 'states/useOTPModal';
import BButton from './BButton';
import Modal from './Modal';

const OTPModal: FunctionComponent = () => {
  const { t } = useTranslation('common');
  const { showOTPModal, setShowOTPModal } = useOTPModalState();
  const [otp, setOtp] = useState<string>('');
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const sendOtp = async () => {
    const message = await authService.sendOTP();
    toast.success(message, { toastId: 'OTPModalSend' });
  };

  useComponentDidUpdate(() => {
    if (showOTPModal) {
      sendOtp();
    }
  }, [showOTPModal]);

  const verifyOTPMessage = async () => {
    setDisableButton(true);
    try {
      await authService.verifyOTP({
        verificationCode: otp,
      });
      toast.success(t('otp.successMessage'));
      authService.setOTPCookie(true);
      setShowOTPModal(false);
    } finally {
      setDisableButton(false);
    }
  };

  return (
    <Modal
      modalTitle={t('otp.title')}
      show={showOTPModal}
      setShow={setShowOTPModal}
    >
      <div className="space-y-4">
        <label htmlFor="opt-input" className="block text-base">
          {t('otp.description')}
        </label>
        <BInput
          id="opt-input"
          placeholder={t('otp.placeholder')}
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
        />
        <BButton disabled={disableButton} onClick={verifyOTPMessage} fluid>
          {t('otp.cta')}
        </BButton>
      </div>
    </Modal>
  );
};

export default OTPModal;
