import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';
import { Variants } from 'types/bosphorus.type';
import { cn } from 'utils/cn';

type Priorities = 'contained' | 'outlined' | 'text' | 'none';

interface BButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  fluid?: boolean;
  variant?: Variants;
  priority?: Priorities;
  children: string | ReactNode;
}

const BButton = forwardRef<HTMLButtonElement, BButtonProps>(
  (
    {
      className,
      fluid = false,
      variant = 'primary',
      priority = 'contained',
      children,
      ...attrs
    },
    ref
  ) => {
    const classesByVariantAndPriority: Record<
      Priorities,
      Record<Variants, string>
    > = {
      contained: {
        primary: 'text-white shadow-md hover:bg-primary-600 bg-primary-500',
        secondary: 'text-white shadow-md hover:bg-primary-800 bg-primary-900',
        success: 'text-white shadow-md hover:bg-success-600 bg-success-500',
        danger: 'text-white shadow-md hover:bg-danger-600 bg-danger-500',
        warning: 'text-white shadow-md hover:bg-warning-600 bg-warning-500',
        info: 'text-white shadow-md hover:bg-info-600 bg-info-500',
      },
      outlined: {
        primary:
          'hover:bg-primary-500 hover:text-white shadow-md border-primary-500 text-primary-500',
        secondary:
          'hover:bg-primary-900 hover:text-white shadow-md border-primary-900 text-primary-900',
        success:
          'hover:bg-success-500 hover:text-white shadow-md border-success-500 text-success-500',
        danger:
          'hover:bg-danger-500 hover:text-white shadow-md border-danger-500 text-danger-500',
        warning:
          'hover:bg-warning-500 hover:text-white shadow-md border-warning-500 text-warning-500',
        info: 'hover:bg-info-500 hover:text-white shadow-md border-info-500 text-info-500',
      },
      text: {
        primary: 'hover:bg-slate-100 text-primary-500',
        secondary: 'hover:bg-slate-100 text-primary-900',
        danger: 'hover:bg-slate-100 text-danger-500',
        success: 'hover:bg-slate-100 text-success-500',
        warning: 'hover:bg-slate-100 text-warning-500',
        info: 'hover:bg-slate-100 text-info-500',
      },
      none: {
        primary: '',
        secondary: '',
        danger: '',
        success: '',
        warning: '',
        info: '',
      },
    };

    return (
      <button
        ref={ref}
        className={cn(
          className,
          classesByVariantAndPriority[priority][variant],
          {
            'rounded border transition-all duration-150 ease-linear':
              priority !== 'none',
            'px-6 py-2': priority !== 'text' && priority !== 'none',
            'px-2 py-2': priority === 'text',
            'w-full': fluid,
            'border-transparent':
              priority !== 'outlined' && priority !== 'none',
            'bg-white': priority !== 'contained' && priority !== 'none',
            'bg-transparent': priority === 'text',
            'hover cursor-not-allowed border-transparent bg-slate-200 text-black hover:bg-slate-200 hover:text-black':
              attrs.disabled,
          }
        )}
        {...attrs}
      >
        {children}
      </button>
    );
  }
);

BButton.displayName = 'BButton';
export default BButton;
