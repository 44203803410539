import Dropdown from 'components/common/Dropdown';
import BInput from 'components/Form/BInput';
import { useBreakpoint } from 'hooks/useBreakpoints';
import useTranslation from 'next-translate/useTranslation';
import { useSearchState } from 'states/useSearchState';
import SearchItems from './SearchItems';
import { SearchMobileCard } from './SearchMobileCard';

export const SearchLocationSelector = ({
  onInputChange,
}: {
  onInputChange: (val: string) => void;
}) => {
  const { t } = useTranslation('navbar');
  const { isMobile } = useBreakpoint();
  const {
    showLocationDropdown,
    setShowLocationDropdown,
    searchValue,
    filteredSearchResults,
    locationInputRef,
    selectedLocation,
  } = useSearchState();

  const close = () => {
    setShowLocationDropdown(false);
  };

  const closeWithBlur = () => {
    close();
    locationInputRef?.current?.blur();
  };

  if (isMobile) {
    return (
      <SearchMobileCard
        show={showLocationDropdown}
        setShow={setShowLocationDropdown}
        iconType="lifebuoy"
        title={t('search.location.mobile.title')}
        selectedValue={
          selectedLocation
            ? selectedLocation.name
            : t('search.location.mobile.placeholder')
        }
      >
        <>
          <BInput
            type="text"
            placeholder={t('search.title')}
            value={searchValue}
            onChange={(event) => onInputChange(event.target.value)}
            className="mb-3 focus:outline-none active:outline-none"
          />
          <div className="h-[40vh] overflow-auto">
            <SearchItems searchResults={filteredSearchResults} />
          </div>
        </>
      </SearchMobileCard>
    );
  }
  return (
    <Dropdown
      wrapperClass="mt-2 text-base w-full text-left max-h-60 overflow-y-auto overscroll-contain hidden md:block w-3/4 left-0"
      show={showLocationDropdown}
      onClose={closeWithBlur}
    >
      <SearchItems searchResults={filteredSearchResults} />
    </Dropdown>
  );
};
