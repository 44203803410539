import { Dispatch, useReducer } from 'react';

export type FormAction<State> = {
  type: keyof State | 'REPLACE_STATE';
  isAppend?: boolean;
  payload: State[keyof State] | State;
};

export type FormReducer<State> = (
  state: State,
  action: FormAction<State>
) => State;

type ReducerCallback<State> = (state: State) => void;

type UseForm = <State>(
  defaultState: State,
  cb?: ReducerCallback<State>
) => [State, Dispatch<FormAction<State>>];

const reducerFactory = <State>(
  cb?: ReducerCallback<State>,
  stateInterceptor?: FormReducer<State>
): FormReducer<State> => {
  const reducer: FormReducer<State> = (state, action) => {
    const { type, payload, isAppend } = action;

    if (type === 'REPLACE_STATE') {
      return {
        ...(payload as State),
      };
    }

    const currentState = stateInterceptor
      ? stateInterceptor(state, action)
      : { ...state };

    if (Array.isArray(currentState[type]) && typeof isAppend === 'boolean') {
      // @ts-expect-error array check exists but throws error
      const selectedData = [...currentState[type]];
      if (isAppend) {
        selectedData.push(payload);
      } else {
        selectedData.splice(selectedData.indexOf(payload), 1);
      }
      const resultedState = {
        ...currentState,
        [type]: selectedData,
      };
      cb?.(resultedState);
      return resultedState;
    }
    const resultedState = {
      ...currentState,
      [type]: payload,
    };
    cb?.(resultedState);
    return resultedState;
  };

  return reducer;
};

export const useForm: UseForm = <State>(
  defaultState: State,
  cb?: ReducerCallback<State>,
  stateInterceptor?: FormReducer<State>
) => {
  const reducer = reducerFactory(cb, stateInterceptor);

  const [state, dispatch] = useReducer<FormReducer<State>>(
    reducer,
    defaultState
  );

  return [state, dispatch];
};
