import { analyticsService } from 'services/AnalyticsService';
import { authService } from 'services/AuthService';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface UserStore {
  isUserLoggedIn: boolean;
  setIsUserLoggedIn: () => void;
}

export const useUserState = create<UserStore>()(
  devtools((set) => {
    return {
      isUserLoggedIn: false,
      setIsUserLoggedIn: () =>
        set((state) => {
          const { refreshToken } = authService.getTokensFromCookie();
          analyticsService.userInfo();
          return {
            ...state,
            isUserLoggedIn: !!refreshToken,
          };
        }),
    };
  })
);
