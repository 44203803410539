import { Fragment, FunctionComponent, ReactNode } from 'react';
import Breakpoint from '../Breakpoint';
import Container from '../Container';
import AppPromotionBanner from '../Mobile/AppPromotionBanner';
import BottomNavigationBar from '../Mobile/BottomNavigationBar';
// import CorporatePromotionBanner from '../Mobile/CorporatePromotionWidget';
import CookieBanner from './CookieBanner';
import Footer from './Footer/Footer';
import Navbar from './Navbar';
import SocialNetworks from './SocialNetworks';
// import WinterPromotionBanner from '../Mobile/WinterPromotionBanner';

export interface LayoutProps {
  children: ReactNode;
  isWebView: boolean;
}

const Layout: FunctionComponent<LayoutProps> = ({ children, isWebView }) => {
  const opacity = isWebView ? 'opacity-0' : '';
  return (
    <>
      <AppPromotionBanner />
      {/* <CorporatePromotionBanner /> */}
      {/* <WinterPromotionBanner /> */}
      <div className="relative">
        <Navbar className={opacity} />
        <Container>{children}</Container>
      </div>
      <CookieBanner className={opacity} />
      {isWebView ? null : (
        <>
          <SocialNetworks />
          <Footer />
          <Breakpoint type="max" bp="MD">
            <BottomNavigationBar />
          </Breakpoint>
        </>
      )}
    </>
  );
};

export default Layout;
