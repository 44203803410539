import { AuthModalMode } from 'enums/authModalMode';
import { SocialLoginType } from 'enums/socialLoginType';
import { FunctionComponent } from 'react';
import { analyticsService } from 'services/AnalyticsService';
import { firebaseAuthService } from 'services/FirebaseAuthService';
import { useUserState } from 'states/useUserState';
import { AuthModalIndividualProps } from 'types/auth.type';
import SocialLoginButton from './SocialLoginButton';

const SocialLoginSection: FunctionComponent<AuthModalIndividualProps> = ({
  phoneNumber,
  onModeChange,
}) => {
  const { setIsUserLoggedIn } = useUserState();

  const onLogin = async (type: SocialLoginType) => {
    const authResponse = await firebaseAuthService.socialSignIn(type);
    const isRegisteredBefore = firebaseAuthService.isUserRegistered(
      authResponse.userInfo
    );
    if (!isRegisteredBefore) {
      analyticsService.signUp(type);
      onModeChange({
        mode: AuthModalMode.PHONE_NUMBER_FORM,
        phoneNumber,
        userInfo: authResponse.userInfo,
      });
    } else {
      analyticsService.login();
      firebaseAuthService.setTokens();
      setIsUserLoggedIn();
    }
  };

  const onGoogleLogin = () => onLogin(SocialLoginType.GOOGLE);
  const onAppleLogin = () => onLogin(SocialLoginType.APPLE);

  // telefon numarasi, iys, country alinacak
  // otp eklenecek

  return (
    <div className="mt-6 flex flex-col gap-4">
      <SocialLoginButton
        label={'Google ile devam et'}
        iconLink="google-icon.svg"
        onClick={onGoogleLogin}
      />
      <SocialLoginButton
        label={'Apple ile devam et'}
        iconLink="apple-logo.svg"
        onClick={onAppleLogin}
      />
    </div>
  );
};

export default SocialLoginSection;
