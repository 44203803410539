// import { Locale } from 'enums/locale';
import Link from 'next-translate-routes/link';
import { Url } from 'next-translate-routes/types';
import { LinkProps } from 'next/link';
// import { useRouter } from 'next/router';
import { FunctionComponent, ReactNode } from 'react';
// import { localizationService } from 'services/LocalizationService';

interface TranslatedLinkProps extends LinkProps {
  href: string | Url;
  children: ReactNode;
}

const TranslatedLink: FunctionComponent<TranslatedLinkProps> = ({
  href,
  children,
  ...props
}) => {
  // const { locale } = useRouter();
  // const as = localizationService.translateRoute(href, locale as Locale);

  return (
    <Link href={href} {...props}>
      {children}
    </Link>
  );
};

export default TranslatedLink;
