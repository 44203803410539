import { NonFunctionStatePropertyNames } from 'types/state.type';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface OTPModalState {
  showOTPModal: boolean;
  setShowOTPModal: (val: OTPModalState['showOTPModal']) => void;
}

export const useOTPModalState = create<OTPModalState>()(
  devtools((set) => {
    const setter =
      (key: NonFunctionStatePropertyNames<OTPModalState>) =>
      (val: OTPModalState[typeof key]) =>
        set((state) => ({ ...state, [key]: val }));
    return {
      showOTPModal: false,
      setShowOTPModal: setter('showOTPModal'),
    };
  })
);
