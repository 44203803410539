import classNames from 'classnames';
import { FunctionComponent, InputHTMLAttributes } from 'react';

interface BSwitchProps {
  value: boolean;
  label?: string;
  disabled?: boolean;
  onChange: (val: boolean) => void;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  wrapperClasses?: string;
  labelClasses?: string;
}

const BSwitch: FunctionComponent<BSwitchProps> = ({
  inputProps,
  value,
  onChange,
  wrapperClasses,
  disabled = false,
  label,
  labelClasses = '',
}) => {
  const handleChange = (val: boolean) => {
    if (disabled) {
      return;
    }
    onChange(val);
  };

  return (
    <div className={classNames('b-switch-wrapper', 'flex', wrapperClasses)}>
      <div className="flex items-center">
        <div
          onClick={() => handleChange(!value)}
          onKeyDown={(e) => e.key === 'Enter' && handleChange(!value)}
          className={classNames(
            'b-switch',
            'relative flex h-7 w-11 cursor-pointer items-center rounded-3xl border py-0.5 px-1 shadow transition-all duration-150',
            {
              'justify-end border-primary-500 bg-primary-500': value,
            },
            {
              'justify-start border-slate-300 bg-slate-300': !value,
            },
            { '!cursor-not-allowed': disabled }
          )}
          role="checkbox"
          tabIndex={0}
          aria-checked={value}
        >
          <input
            {...inputProps}
            className="absolute scale-0"
            type="checkbox"
            checked={value}
            onChange={(event) => handleChange(event.target.checked)}
          />
          <span
            className={classNames(
              'inline-block h-5 w-5 rounded-full border-2 border-white bg-white',
              { 'bg-transparent': disabled }
            )}
          />
        </div>
        {label ? (
          <span className={classNames('ml-1 inline-block', labelClasses)}>
            {label}
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default BSwitch;
