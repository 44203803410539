export enum VehicleType {
  INVITATION_BOAT = 'INVITATION_BOAT',
  YACHT = 'YACHT',
  GULET = 'GULET',
  SAIL_BOAT = 'SAIL_BOAT',
  CATAMARAN = 'CATAMARAN',
  RIB = 'RIB',
  SPEED_BOAT = 'SPEED_BOAT',
  TRIMARAN = 'TRIMARAN',
  SWIMMING_HOUSE = 'SWIMMING_HOUSE',
}
