import React, { FunctionComponent, ReactNode } from 'react';

interface ContainerProps {
  children: ReactNode;
}

const Container: FunctionComponent<ContainerProps> = ({ children }) => {
  return <main className="tkv-container mx-auto mt-8">{children}</main>;
};

export default Container;
