import classNames from 'classnames';
import BButton from 'components/common/BButton';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent } from 'react';

interface AuthModalLabelItemProps {
  className: string;
  labelKey: string;
  actionKey: string;
  onClick: () => void;
}

const AuthModalLabelItem: FunctionComponent<AuthModalLabelItemProps> = ({
  className,
  labelKey,
  actionKey,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <div className={classNames('flex items-center justify-center', className)}>
      <span>{t(labelKey)}</span>
      <BButton
        className="ml-2 cursor-pointer text-primary-500 hover:underline"
        onClick={onClick}
        priority="none"
      >
        {t(actionKey)}
      </BButton>
    </div>
  );
};

export default AuthModalLabelItem;
