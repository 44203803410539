import classNames from 'classnames';
import ChevronLeftIcon from 'components/Icons/chevronLeft.svg';
import CloseIcon from 'components/Icons/close.svg';
import { useSwipe } from 'hooks/useSwipe';
import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useModalState } from 'states/useModalState';
import BButton from './BButton';
import ClientOnlyPortal from './ClientOnlyPortal';

interface ModalProps {
  show: boolean;
  setShow: (val: boolean) => void;
  noLayout?: boolean;
  modalClass?: string;
  overlayClass?: string;
  modalTitle?: ReactNode;
  modalTitleClass?: string;
  modalBodyClass?: string;
  fullScreen?: boolean;
  children: ReactNode;
  showBackButton?: boolean;
  blocking?: boolean;
  nonScrollable?: boolean;
  hideWithCss?: boolean;
  removeBottomMargin?: boolean;
  removeSlideToClose?: boolean;
}

const Modal: FunctionComponent<ModalProps> = ({
  show,
  setShow,
  modalClass,
  overlayClass,
  modalTitle,
  modalTitleClass,
  children,
  modalBodyClass,
  fullScreen = false,
  noLayout = false,
  showBackButton = false,
  blocking = false,
  hideWithCss = false,
  removeBottomMargin = false,
  removeSlideToClose = false,
  nonScrollable = true,
}) => {
  const modalId = useMemo(
    () => Date.now().toString(36) + Math.random().toString(36).substring(2),
    []
  );
  const { currentOpenModals, updateCurrentOpenModals } = useModalState();

  const closeOnEscape = useCallback((event: globalThis.KeyboardEvent) => {
    if (!blocking && event.key === 'Escape') {
      const lastOpenedModal = document.querySelector(
        '[data-modal-id]:last-child'
      );
      const lastOpenedModalId = lastOpenedModal?.getAttribute('data-modal-id');
      if (lastOpenedModalId && lastOpenedModalId === modalId) setShow(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keyup', closeOnEscape);
    return () => {
      document.removeEventListener('keyup', closeOnEscape);
      updateCurrentOpenModals('close', modalId);
    };
  }, []);

  useEffect(() => {
    if (show) {
      nonScrollable && updateCurrentOpenModals('open', modalId);
    } else {
      nonScrollable && updateCurrentOpenModals('close', modalId);
    }
  }, [show]);

  const desktopClasses =
    'md:bottom-[unset] md:right-[unset] md:top-1/2 md:left-1/2 md:-translate-y-1/2 md:-translate-x-1/2 md:rounded-xl';

  const mobileClasses = 'top-0 left-0 bottom-0 right-0';

  const zIndex = fullScreen ? 'z-[999]' : 'z-400';

  const isFirstModal = useMemo(() => {
    return currentOpenModals[0] === modalId;
  }, [currentOpenModals, modalId]);

  const { handleTouchEnd, handleTouchStart, handleTouchMove } = useSwipe(
    {
      left: () => setShow(false),
    },
    { selector: `[data-modal-id=${modalId}]`, swipeBoundary: { left: 24 } }
  );

  return !show && !hideWithCss ? null : (
    <ClientOnlyPortal selector="#modal">
      <div
        className={classNames(
          'fixed top-0 left-0 h-full w-full bg-slate-900 opacity-50',
          zIndex,
          overlayClass,
          hideWithCss && !show ? 'hidden' : ''
        )}
        role="presentation"
        onClick={blocking ? undefined : () => setShow(false)}
      />
      <div
        role="dialog"
        data-modal-id={modalId}
        {...(!removeSlideToClose
          ? {
              onTouchEnd: handleTouchEnd,
              onTouchMove: handleTouchMove,
              onTouchStart: handleTouchStart,
            }
          : {})}
        className={classNames(
          zIndex,
          modalClass,
          desktopClasses,
          mobileClasses,
          {
            'p-0': fullScreen,
            'px-6': !fullScreen,
          },
          'fixed max-h-screen min-w-[50%] overflow-auto bg-white lg:min-w-[40%] xl:min-w-[30%]',
          hideWithCss && !show ? 'hidden' : ''
        )}
      >
        {noLayout ? null : (
          <div
            className={classNames(
              'sticky top-0 z-10 grid grid-cols-12 items-center bg-white pt-4',
              modalTitleClass,
              {
                'pb-4': modalTitle,
                'pb-2': !modalTitle,
              }
            )}
          >
            {!blocking && (!isFirstModal || showBackButton) ? (
              <BButton
                variant="secondary"
                priority="text"
                className="col-span-1 justify-self-start !p-0"
                onClick={() => setShow(false)}
              >
                <ChevronLeftIcon className="h-6 w-6" />
              </BButton>
            ) : null}
            <div className="col-span-11 inline-block w-full justify-self-center text-lg md:text-xl">
              {modalTitle}
            </div>
            {!blocking && isFirstModal && !showBackButton ? (
              <BButton
                variant="secondary"
                priority="text"
                className="col-span-1 justify-self-end !p-0"
                onClick={() => setShow(false)}
              >
                <CloseIcon className="h-6 w-6" />
              </BButton>
            ) : null}
          </div>
        )}
        <div
          className={classNames(
            {
              'mb-[var(--bottom-navigation-menu-height)] pb-12 md:mb-6 md:pb-0':
                !fullScreen,
              '!pb-0': removeBottomMargin,
            },
            modalBodyClass
          )}
        >
          {children}
        </div>
      </div>
    </ClientOnlyPortal>
  );
};

export default Modal;
