import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/tr';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { Locale } from 'enums/locale';

dayjs.locale('tr');

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(isBetween);

class DateService {
  format = 'DD.MM.YYYY';
  readableDate = 'D MMM YYYY ddd';
  readableShortDate = 'D MMM';
  timeFormat = 'HH:mm';

  getDayjs() {
    return dayjs;
  }

  formatDateToServer(date: Dayjs | Date) {
    return dayjs(date).utc(true).toISOString();
  }

  formatUtcDateToLocalTz(date: Dayjs | Date | string) {
    return dayjs.tz(date, dayjs.tz.guess()).toDate();
  }

  getTimeZone() {
    return dayjs.tz.guess();
  }

  setTimeZone(date: Dayjs | Date | string, tz: string) {
    return dayjs.tz(date, tz).toDate();
  }

  /*
    Creation date gibi yerler icin gerekli
  */
  formatServerUTCToLocal(date: string, isUtc = true) {
    return dayjs(date).utc(isUtc).tz(dayjs.tz.guess()).toDate();
  }

  formatServerDateToLocalDate(date: string) {
    return new Date(date);
  }

  // formatServerUtcToPartnerizedLocalDate(date: string, vehicleTimeZone: string) {
  //   return dayjs(date).utc(true).tz(vehicleTimeZone).tz(dayjs.tz.guess(), true).toDate();
  // }

  checkIfDateInRange(date: Date, startDate: Date, endDate: Date) {
    return dayjs(date).isBetween(startDate, endDate, 'hour', '[]');
  }

  getReadableDate(date: Date | string) {
    return dayjs(date).format(dateService.readableDate);
  }

  getReadableDateTime(date: Date) {
    return dayjs(date).format(
      `${dateService.readableDate} ${dateService.timeFormat}`
    );
  }

  getReadableTime(date: Date) {
    return dayjs(date).format(dateService.timeFormat);
  }

  getFormattedDate(date: Date) {
    return dayjs(date).format(dateService.format);
  }

  getFormattedDateTime(date: Date) {
    return dayjs(date).format(
      `${dateService.format} ${dateService.timeFormat}`
    );
  }

  getReadableShortRangeDateTime(
    startTime: Date,
    startHour: number | null,
    endHour: number | null
  ) {
    const startFormat = dayjs(startTime);

    if (startHour === null && endHour === null) {
      return (
        startFormat.format(`${dateService.readableShortDate}`) + ' ... - ...'
      );
    } else if (endHour === null && startHour !== null) {
      return (
        startFormat
          .hour(startHour)
          .format(
            `${dateService.readableShortDate} ${dateService.timeFormat}`
          ) + ' - ...'
      );
    } else if (startHour !== null && endHour !== null) {
      return (
        startFormat
          .hour(startHour)
          .format(
            `${dateService.readableShortDate} ${dateService.timeFormat}`
          ) +
        ' - ' +
        dayjs(startTime).hour(endHour).format(dateService.timeFormat)
      );
    }
    return '';
  }

  getReadableShortRangeDate(startTime: Date, endTime?: Date | null) {
    let startFormat = dayjs(startTime).format(dateService.readableShortDate);
    startFormat = startFormat + ' - ';
    startFormat =
      startFormat +
      (endTime ? dayjs(endTime).format(dateService.readableShortDate) : '...');
    return startFormat;
  }

  getEndDateFromHourDuration(startDate: Date, duration: number) {
    return dayjs(startDate).add(duration, 'hour');
  }

  getCurrentYear() {
    return dayjs().year();
  }

  setLocale(locale: Locale) {
    dayjs.locale(locale);
  }

  isSameDate(fistDate: Date, secondDate: Date) {
    return dayjs(fistDate).isSame(dayjs(secondDate), 'day');
  }

  getReadableDuration(millis: number) {
    return dayjs.duration(millis).humanize();
  }

  getDayIndexAlignedWithDayjs(dayIndex: number): number {
    return (dayIndex + 1) % 7; // Sunday Offset
  }

  dayIndexToDayString(dayIndex: number): string {
    return dayjs()
      .day(dateService.getDayIndexAlignedWithDayjs(dayIndex))
      .format('dddd');
  }

  getDuration(startTime: Date | Dayjs, endTime: Date | Dayjs) {
    const dayjsStartDate = dayjs(startTime);
    const dayjsEndDate = dayjs(endTime);
    return dayjs.duration(dayjsEndDate.diff(dayjsStartDate));
  }

  getHourDuration(startTime: Date, endTime: Date) {
    return dateService.getDuration(startTime, endTime).asHours();
  }

  getDayDuration(startTime: Date, endTime: Date) {
    return dateService
      .getDuration(
        dayjs(startTime).startOf('day'),
        dayjs(endTime).startOf('day')
      )
      .asDays();
  }

  getWeekDuration(startTime: Date, endTime: Date) {
    return dateService
      .getDuration(
        dayjs(startTime).startOf('day'),
        dayjs(endTime).startOf('day')
      )
      .asWeeks();
  }

  formatSearchDate(date: Date) {
    return dayjs(date).format('YYMMDDHH');
  }

  isPastThenCurrentMonth(date: Date) {
    return dayjs(date).diff(new Date(), 'month') < 0;
  }

  getDateDay(date: Date) {
    return dayjs(date).format('dddd');
  }

  resetMinuteBelow(date: Date) {
    return dayjs(date).minute(0).second(0).millisecond(0).toDate();
  }
}

export const dateService = new DateService();
