import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface IsMobileState {
  isMobile: boolean;
  setIsMobile: (val: boolean) => void;
}

export const useIsMobileState = create<IsMobileState>()(
  devtools((set) => {
    return {
      isMobile: false,
      setIsMobile: (val) =>
        set((state) => {
          return {
            ...state,
            isMobile: val,
          };
        }),
    };
  })
);
