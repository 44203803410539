import { Locale } from 'enums/locale';
import { useRouter } from 'next/router';

interface UseLocaleReturn {
  locale: Locale;
  isBaseLocale: boolean;
}

export function useLocale(): UseLocaleReturn {
  const { locale } = useRouter();
  const isBaseLocale = locale === Locale.TR;
  return { locale: locale as Locale, isBaseLocale };
}
