import { useEffect, useRef } from 'react';

export function useComponentDidUpdate(
  callback: () => void,
  dependencies: unknown[]
) {
  const limit = process.env.NODE_ENV === 'development' ? 2 : 1;
  const isFirstRender = useRef<number>(0);

  useEffect(() => {
    if (isFirstRender.current >= limit) {
      callback();
    }
    isFirstRender.current++;
  }, [...dependencies]);
}
