import { FunctionComponent } from 'react';
import ClientOnlyPortal from './ClientOnlyPortal';

const BSpinner: FunctionComponent = () => {
  return (
    <ClientOnlyPortal selector="#spinner">
      <div className="fixed top-0 left-0 z-[1000] flex h-full w-full items-center justify-center bg-slate-400 bg-opacity-20" />
      <div className="loader" />
      <style jsx>{`
        .loader {
          --spinner-main-clr: var(--clr-primary-500);
          --spinner-bg-clr: var(--clr-white);
          width: 64px;
          height: 64px;
          position: fixed;
          background: var(--spinner-bg-clr);
          top: calc(50% - 32px);
          left: calc(50% - 32px);
          transform: rotate(45deg);
          border-radius: 50%;
          animation: rotate 2s linear infinite alternate;
          z-index: 1000;
          box-shadow: 0 0 6px 1px rgb(0 0 0 / 0.1);
        }
        .loader:before {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          width: 15px;
          height: 30px;
          background: var(--spinner-main-clr);
          transform: skew(5deg, 60deg) translate(-50%, -5%);
        }

        .loader:after {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: var(--spinner-bg-clr);
          transform: translate(-50%, -50%);
        }

        @keyframes rotate {
          0% {
            transform: rotate(45deg);
          }
          30%,
          50%,
          70% {
            transform: rotate(230deg);
          }
          40%,
          60%,
          80% {
            transform: rotate(240deg);
          }
          100% {
            transform: rotate(245deg);
          }
        }
      `}</style>
    </ClientOnlyPortal>
  );
};

export default BSpinner;
