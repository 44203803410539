import { BusinessModel } from 'enums/businessModel';
import { SearchQueryKey } from 'enums/searchQueryKey';
import { useSearchState } from 'states/useSearchState';
import { SearchState } from 'types/search.type';
import { slugifyText } from 'utils/slugify';
import { useQueryParams } from './useQueryParams';
import { useRouter } from 'next/router';
import { dateService } from 'services/DateService';
import { SearchLocationType } from 'enums/SearchLocationType';
import { linkService } from 'services/LinkService';

export function useSearchFromQuery() {
  const {
    allSearchData,
    setShowLocationDropdown,
    setSearchValue,
    setStartTime,
    setEndTime,
    setNumberOfPeople,
    setBusinessModel,
    setIsFlexSearch,
    setEndHour,
    setStartHour,
    setSelectedLocation,
    setFilteredSearchResults,
  } = useSearchState();
  const router = useRouter();
  const { getQueryParam } = useQueryParams();

  const filterSearchResults = (query: string) => {
    const slugifiedQuery = slugifyText(query);
    const filteredResults = allSearchData.reduce((acc, item) => {
      if (item.slug.includes(slugifiedQuery)) {
        const filteredChildren = item.children?.filter((child) =>
          child.slug.includes(slugifiedQuery)
        );
        acc.push({
          ...item,
          children: filteredChildren,
        });
      } else {
        const children = item.children?.filter((child) =>
          child.slug.includes(slugifiedQuery)
        );
        if (children?.length) {
          acc.push({ ...item, children });
        }
      }
      return acc;
    }, [] as SearchState[]);
    setFilteredSearchResults(filteredResults);
  };

  const findLocationFromSlug = (
    slugifiedSearch: string,
    options?: { showError?: boolean }
  ): SearchState | undefined => {
    if (slugifiedSearch === 'turkey') {
      return {
        type: SearchLocationType.COUNTRY,
        name: 'Turkey',
        slug: 'turkey',
        vehicleCount: 0,
      };
    }
    if (slugifiedSearch === 'turkiye') {
      return {
        type: SearchLocationType.COUNTRY,
        name: 'Türkiye',
        slug: 'turkiye',
        vehicleCount: 0,
      };
    }

    const { showError = true } = options || {};
    if (filterSearchResults.length === 0) {
      if (showError) {
        setShowLocationDropdown(true);
      }
      return;
    }

    const flattenedSearchResults = allSearchData.reduce((acc, item) => {
      return [
        ...acc,
        item,
        ...(item.children?.map((a) => ({ ...a, parentSlug: item.slug })) || []),
      ];
    }, [] as SearchState[]);

    const isLocationAvailable = flattenedSearchResults.find(
      (loc) => loc.slug === slugifiedSearch
    );

    if (!isLocationAvailable) {
      if (showError) {
        setShowLocationDropdown(true);
      }
      return;
    }
    return isLocationAvailable;
  };

  const getLocationFromRoute = () => {
    const lastLoc = linkService.extractLocationFromCategoryUrl(
      router.query.category as string
    );

    return findLocationFromSlug(lastLoc ? lastLoc.location : '', {
      showError: false,
    });
  };

  const getStateFromQuery = () => {
    const foundLocation = getLocationFromRoute();

    const st = getQueryParam(SearchQueryKey.START_TIME) as string;
    const et = getQueryParam(SearchQueryKey.END_TIME) as string;
    const nop = getQueryParam(SearchQueryKey.NUMBER_OF_PEOPLE) as string;
    const bm = getQueryParam(SearchQueryKey.BUSINESS_MODEL) as string;
    const fs = getQueryParam(SearchQueryKey.FLEX_SEARCH) as string;
    const tz = getQueryParam(SearchQueryKey.TIMEZONE) as string;
    return {
      startTime: st,
      endTime: et,
      numberOfPeople: nop ? Number(nop) : undefined,
      businessModel:
        bm && Object.values(BusinessModel).includes(bm as BusinessModel)
          ? (bm as BusinessModel)
          : null,
      flexDates: fs === 'false' ? false : true,
      location: foundLocation,
      tz,
    };
  };

  const setStateFromQuery = () => {
    const {
      startTime: st,
      endTime: et,
      numberOfPeople: nop,
      businessModel: bm,
      flexDates: fs,
      location,
      tz,
    } = getStateFromQuery();

    let startTime;
    let endTime;
    if (typeof window !== 'undefined') {
      startTime = st ? dateService.formatUtcDateToLocalTz(st) : null;
      endTime = et ? dateService.formatUtcDateToLocalTz(et) : null;
    } else {
      startTime = st ? dateService.setTimeZone(st, tz) : null;
      endTime = et ? dateService.setTimeZone(et, tz) : null;
    }

    startTime ? setStartTime(startTime) : setStartTime(null);
    endTime ? setEndTime(endTime) : setEndTime(null);
    startTime ? setStartHour(startTime.getHours()) : setStartHour(null);
    endTime ? setEndHour(endTime.getHours()) : setEndHour(null);
    nop ? setNumberOfPeople(nop) : setNumberOfPeople(0);
    fs !== null ? setIsFlexSearch(fs) : setIsFlexSearch(true);
    if (bm) {
      setBusinessModel(bm);
    } else {
      if (location) {
        if (
          location.parentSlug === 'istanbul' ||
          location.slug === 'istanbul'
        ) {
          setBusinessModel(BusinessModel.HOURLY);
        } else {
          setBusinessModel(BusinessModel.DAILY_WEEKLY);
        }
      } else {
        setBusinessModel(BusinessModel.HOURLY);
      }
    }

    if (location) {
      setSearchValue(location.name);
      setSelectedLocation(location);
    } else {
      setSearchValue('');
      setSelectedLocation(null);
    }
  };

  return {
    filterSearchResults,
    getStateFromQuery,
    setStateFromQuery,
    getLocationFromRoute,
  };
}
