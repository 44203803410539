import { GetServerSidePropsContext } from 'next';
import { VehicleSummary } from 'types/vehicle.type';
import { client } from 'utils/axios';
import { ssrRequest } from 'utils/ssrRequestFactory';

class FavoriteService {
  async getAllFavoritesSSR(
    ctx: GetServerSidePropsContext
  ): Promise<VehicleSummary[]> {
    return ssrRequest('/v1/customer/favorites', ctx);
  }

  async getAllFavorites(): Promise<VehicleSummary[]> {
    const { data } = await client.get('/v1/customer/favorites', {
      hideError: true,
      hideSpinner: true,
    });
    return data;
  }

  async addFavorite(vehicleId: number) {
    return client.post(
      '/v1/customer/favorites',
      {
        vehicleId,
      },
      {
        hideError: true,
        hideSpinner: true,
      }
    );
  }

  async removeFavorite(vehicleId: number) {
    return client.delete(`/v1/customer/favorites/${vehicleId}`, {
      hideError: true,
      hideSpinner: true,
    });
  }
}

export const favoriteService = new FavoriteService();
