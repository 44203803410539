import classNames from 'classnames';
import { CSSProperties, FunctionComponent, ReactNode } from 'react';
import { DropdownProps } from 'types/dropdown.type';
import { cn } from 'utils/cn';

interface DropdownPropsWithChildren extends DropdownProps {
  children: ReactNode[] | ReactNode;
  wrapperClass?: string;
  dropdownStyle?: CSSProperties;
}

const Dropdown: FunctionComponent<DropdownPropsWithChildren> = ({
  show,
  onClose,
  children,
  wrapperClass,
  withoutModal = false,
  dropdownStyle,
}) => {
  return (
    <div
      aria-hidden={show ? 'false' : 'true'}
      className={classNames({ hidden: !show })}
      role="listbox"
    >
      {withoutModal ? null : (
        <div
          className="fixed top-0 left-0 z-10 h-full w-full"
          onClick={onClose}
          role="presentation"
        />
      )}
      <div
        className={cn(
          'absolute top-12 right-0 z-10 rounded-lg border bg-white shadow',
          wrapperClass
        )}
        style={dropdownStyle}
      >
        {children}
      </div>
    </div>
  );
};

export default Dropdown;
