import classNames from 'classnames';
import { FunctionComponent, ReactNode } from 'react';
import { Variants } from 'types/bosphorus.type';

interface BNotifBadgeProps {
  variant?: Variants;
  children?: ReactNode;
  className?: string;
}

const BNotifBadge: FunctionComponent<BNotifBadgeProps> = ({
  // variant = 'info',
  children,
  className,
}) => {
  // const classesByVariant: Record<Variants, string> = {
  //   primary: 'bg-primary-500',
  //   secondary: 'bg-slate-500',
  //   success: 'bg-success-500',
  //   danger: 'bg-danger-600',
  //   warning: 'bg-warning-500',
  //   info: '',
  // };

  return (
    <span
      className={classNames(
        'inline-block rounded-full bg-[#ff3040] text-xs text-white',
        {
          'p-2': !children,
          'py-1 px-2': children,
        },
        className
        // classesByVariant[variant]
      )}
    >
      {children}
    </span>
  );
};

export default BNotifBadge;
