import React, {
  FunctionComponent,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  useState,
} from 'react';
import BLabelWrapper, {
  BLabelWrapperPropsWithoutNatives,
} from './BLabelWrapper';
import BInput from './BInput';
import CdnSvg from 'components/common/CdnSvg';
import { cn } from 'utils/cn';

interface BPasswordInputProps
  extends BLabelWrapperPropsWithoutNatives,
    InputHTMLAttributes<HTMLInputElement> {
  labelAttrs?: LabelHTMLAttributes<HTMLLabelElement>;
}

const BPasswordInput: FunctionComponent<BPasswordInputProps> = ({
  wrapperClassName = '',
  labelClassName = '',
  label = '',
  className,
  labelAttrs,
  ...inputAttrs
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const iconSize = 26;

  return (
    <BLabelWrapper
      wrapperClassName={cn(wrapperClassName, 'relative')}
      labelClassName={labelClassName}
      label={label}
      {...labelAttrs}
    >
      <BInput
        {...inputAttrs}
        type={showPassword ? 'text' : 'password'}
        className={className}
      />
      <div
        className="absolute bottom-2 right-3 z-10 text-primary-500"
        onClick={() => setShowPassword(!showPassword)}
        tabIndex={0}
        role="button"
      >
        {showPassword ? (
          <CdnSvg src="eye.svg" size={iconSize} />
        ) : (
          <CdnSvg src="eye-closed.svg" size={iconSize} />
        )}
      </div>
    </BLabelWrapper>
  );
};

export default BPasswordInput;
