import { useEffect } from 'react';
import { authService } from 'services/AuthService';
import {
  LocalStorageKeys,
  localStorageService,
} from 'services/LocalStorageService';
import { useUserState } from 'states/useUserState';

export const useAdminCredentialByPass = () => {
  const { setIsUserLoggedIn } = useUserState();
  useEffect(() => {
    const messageListener = (
      event: MessageEvent<{
        type: string;
        tokens: { accessToken: string; refreshToken: string };
      }>
    ): void => {
      if (event.data.type === 'admin-login-bypass') {
        authService.removeTokensInCookies();
        authService.setTokensToCookie(event.data.tokens);
        setIsUserLoggedIn();
        window.removeEventListener('message', messageListener);
        localStorageService.setItem(LocalStorageKeys.IS_ADMIN, 'true');
      }
    };
    window.addEventListener('message', messageListener);
    window.parent.postMessage({ type: 'admin-login-bypass-ready' }, '*');
    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, [setIsUserLoggedIn]);
};
