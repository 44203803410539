import React, {
  FunctionComponent,
  InputHTMLAttributes,
  LabelHTMLAttributes,
} from 'react';
import BLabelWrapper, {
  BLabelWrapperPropsWithoutNatives,
} from './BLabelWrapper';
import BInput from './BInput';

interface BLabeledInputProps
  extends BLabelWrapperPropsWithoutNatives,
    InputHTMLAttributes<HTMLInputElement> {
  labelAttrs?: LabelHTMLAttributes<HTMLLabelElement>;
}

const BLabeledInput: FunctionComponent<BLabeledInputProps> = ({
  wrapperClassName = '',
  labelClassName = '',
  label = '',
  className,
  labelAttrs,
  ...inputAttrs
}) => {
  return (
    <BLabelWrapper
      wrapperClassName={wrapperClassName}
      labelClassName={labelClassName}
      label={label}
      {...labelAttrs}
    >
      <BInput className={className} {...inputAttrs} />
    </BLabelWrapper>
  );
};

export default BLabeledInput;
