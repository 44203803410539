import classNames from 'classnames';
import CdnSvg from 'components/common/CdnSvg';
import TranslatedLink from 'components/common/TranslatedLink';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { FunctionComponent, useMemo } from 'react';
import { cn } from 'utils/cn';

const routesShownLogoInMobile = ['/partner', '/', '/yardim-merkezi'];

interface NavbarLogoProps {
  wrapperClasses?: string;
  intersecting: boolean;
}

const NavbarLogo: FunctionComponent<NavbarLogoProps> = ({
  wrapperClasses,
  intersecting,
}) => {
  const { t } = useTranslation('navbar');
  const { pathname } = useRouter();

  const showMobileLogo = useMemo(
    () => routesShownLogoInMobile.some((route) => pathname === route),
    [pathname]
  );

  return (
    <div
      className={classNames(
        'navbar-logo w-full md:w-min lg:w-1/4',
        {
          'hidden md:block': !showMobileLogo,
          'md:block': showMobileLogo,
        },
        wrapperClasses
      )}
    >
      <TranslatedLink href="/" prefetch={false}>
        <a
          className="mx-auto flex w-max items-center md:mx-0"
          title={t('linkTitles.homepage')}
        >
          <CdnSvg
            prioritized
            className={cn({ hidden: intersecting })}
            src="teknevia-logo.svg"
            height={55}
            width={32}
          />
          <CdnSvg
            prioritized
            className={cn('ml-2', { hidden: intersecting })}
            src="teknevia-logo-text.svg"
            height={32}
            width={161}
          />
          <CdnSvg
            prioritized
            className={cn({ hidden: !intersecting })}
            src="teknevia-logo-white.svg"
            height={55}
            width={32}
          />
          <CdnSvg
            prioritized
            className={cn('ml-2', { hidden: !intersecting })}
            src="teknevia-logo-white-text.svg"
            height={32}
            width={161}
          />
        </a>
      </TranslatedLink>
    </div>
  );
};

export default NavbarLogo;
