import CdnSvg from 'components/common/CdnSvg';
import { ReactNode } from 'react';
import { useSearchState } from 'states/useSearchState';
import { cn } from 'utils/cn';

interface SearchMobileCardProps {
  children: ReactNode;
  iconType: 'lifebuoy' | 'people' | 'date';
  title: ReactNode;
  selectedValue: ReactNode;
  show: boolean;
  setShow: (val: boolean) => void;
  wrapperClass?: string;
  titleClass?: string;
}

export const SearchMobileCard = ({
  children,
  iconType,
  title,
  selectedValue,
  show,
  setShow,
  wrapperClass,
  titleClass,
}: SearchMobileCardProps) => {
  const {
    setShowDateDropdown,
    setShowLocationDropdown,
    setShowNumberOfPeopleDropdown,
  } = useSearchState();

  const icon = (
    {
      lifebuoy: (
        <CdnSvg src="lifebuoy.svg" className={show ? 'h-6 w-6' : 'h-5 w-5'} />
      ),
      people: (
        <CdnSvg src="users.svg" className={show ? 'h-6 w-6' : 'h-5 w-5'} />
      ),
      date: (
        <CdnSvg src="calendar.svg" className={show ? 'h-6 w-6' : 'h-5 w-5'} />
      ),
    } as Record<SearchMobileCardProps['iconType'], ReactNode>
  )[iconType];

  const toggleOpen = () => {
    const newVal = !show;
    if (newVal) {
      setShowDateDropdown(false);
      setShowLocationDropdown(false);
      setShowNumberOfPeopleDropdown(false);
      setShow(newVal);
    }
  };

  return (
    <div
      className={cn(
        'mx-6 rounded-xl border bg-white py-5 px-4 shadow-xl',
        wrapperClass
      )}
    >
      <div
        className={cn(
          'text-semibold flex items-center gap-2',
          {
            'mb-4 text-lg': show,
            'text-base': !show,
          },
          titleClass
        )}
        tabIndex={0}
        role="button"
        onClick={toggleOpen}
      >
        {icon}
        {title}
        {show ? null : (
          <div className="ml-auto inline-block text-sm font-normal">
            {selectedValue}
          </div>
        )}
      </div>
      {show ? children : null}
    </div>
  );
};
