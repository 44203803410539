import Dropdown from 'components/common/Dropdown';
import BStepperInput from 'components/Form/BStepperInput';
import { useBreakpoint } from 'hooks/useBreakpoints';
import useTranslation from 'next-translate/useTranslation';
import { useSearchState } from 'states/useSearchState';
import { SearchMobileCard } from './SearchMobileCard';

export const SearchNumberOfPeopleSelector = () => {
  const { t } = useTranslation('navbar');
  const {
    numberOfPeople,
    setNumberOfPeople,
    showNumberOfPeopleDropdown,
    setShowNumberOfPeopleDropdown,
  } = useSearchState();
  const { isMobile } = useBreakpoint();

  const close = () => {
    setShowNumberOfPeopleDropdown(false);
  };

  if (isMobile) {
    return (
      <SearchMobileCard
        show={showNumberOfPeopleDropdown}
        setShow={setShowNumberOfPeopleDropdown}
        iconType="people"
        title={t('search.numberOfPeople.mobile.title')}
        selectedValue={
          numberOfPeople > 0
            ? t('search.numberOfPeople.person', {
                count: numberOfPeople,
              })
            : t('search.numberOfPeople.placeholder')
        }
      >
        <div className="p-2">
          <div className="flex items-center justify-between">
            <div className="font-medium">
              {t('search.numberOfPeople.label')}
            </div>
            <BStepperInput
              inputProps={{
                className: '!py-1 !w-16',
              }}
              minLimit={0}
              maxLimit={1000}
              iconSize={36}
              value={numberOfPeople}
              wrapperClasses={'gap-3'}
              inputEnabled
              onChange={(value) => setNumberOfPeople(Number(value))}
            />
          </div>
        </div>
      </SearchMobileCard>
    );
  }

  return (
    <Dropdown
      wrapperClass="mt-2 text-base w-full text-left max-h-60 overflow-y-auto overscroll-contain hidden md:block w-1/2 right-0"
      show={showNumberOfPeopleDropdown}
      onClose={close}
    >
      <div className="p-3">
        <label htmlFor="number-of-people">
          <div className="mb-3">{t('search.numberOfPeople.label')}</div>
          <BStepperInput
            inputProps={{
              id: 'number-of-people',
              className: '!py-1 !w-16',
            }}
            minLimit={0}
            maxLimit={1000}
            iconSize={36}
            value={numberOfPeople}
            inputEnabled
            onChange={(value) => setNumberOfPeople(Number(value))}
          />
        </label>
      </div>
    </Dropdown>
  );
};
