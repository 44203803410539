class LocalStorageService {
  getItem(key: LocalStorageKeys) {
    return localStorage.getItem(key);
  }
  setItem(key: LocalStorageKeys, value: string): void {
    return localStorage.setItem(key, value);
  }
}

export const localStorageService = new LocalStorageService();

export enum LocalStorageKeys {
  IS_ADMIN = 'is_admin',
}
