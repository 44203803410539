import classNames from 'classnames';
import { FunctionComponent } from 'react';

interface BDividerProps {
  className?: string;
  colorClass?: string;
}

const BDivider: FunctionComponent<BDividerProps> = ({
  className,
  colorClass,
}) => {
  return (
    <div
      className={classNames('border-b', className, {
        ...(colorClass ? { [colorClass]: true } : { 'border-slate-100': true }),
      })}
    />
  );
};

export default BDivider;
