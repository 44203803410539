import { AxiosRequestConfig } from 'axios';
import { GetServerSidePropsContext } from 'next';
import { ProfilePayload, ProfileResponse } from 'types/profile.type';
import { client } from 'utils/axios';
import { ssrRequest } from 'utils/ssrRequestFactory';

class ProfileService {
  async getProfile(ctx: GetServerSidePropsContext): Promise<ProfileResponse> {
    return ssrRequest('/v1/customer/profile', ctx);
  }

  async updateProfile(
    preferences: ProfilePayload,
    options?: AxiosRequestConfig
  ): Promise<void> {
    const { data } = await client.put(
      '/v1/customer/profile',
      preferences,
      options
    );
    return data;
  }
}

export const profileService = new ProfileService();
