import CdnSvg from 'components/common/CdnSvg';
import useTranslation from 'next-translate/useTranslation';
import { SearchHourOption } from 'types/search.type';

export const useHourGenerator = (
  startHour: number,
  length: number,
  options?: {
    shouldContinueFromNextDay?: boolean;
    shouldStartFromNextDay?: boolean;
  }
) => {
  const { t } = useTranslation('navbar');
  const { shouldContinueFromNextDay = true, shouldStartFromNextDay = false } =
    options || {};
  return new Array(length)
    .fill(0)
    .map((_, idx) => {
      const hour = (startHour + idx) % 24;
      const extraDayOffset = shouldStartFromNextDay
        ? 1
        : Math.floor((startHour + idx) / 24);

      const isNextDay = shouldStartFromNextDay || (idx > 0 && hour < +idx);
      if (isNextDay && !shouldContinueFromNextDay) {
        return;
      }

      return {
        icon:
          hour >= 8 && hour <= 18 ? (
            <CdnSvg src={'sun.svg'} size={16} />
          ) : (
            <CdnSvg src={'moon.svg'} size={16} />
          ),
        label: `${hour.toString().padStart(2, '0')}:00`,
        value: hour,
        prefix: isNextDay && hour === 0 ? t('search.date.nextDay') : null,
        dayOffset: extraDayOffset,
      };
    })
    .filter((a) => a) as SearchHourOption<number>[];
};
