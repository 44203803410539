import { AuthModalMode } from 'enums/authModalMode';
import { SocialLoginType } from 'enums/socialLoginType';

export interface TokensResponse {
  accessToken: string;
  refreshToken: string;
}

export interface RefreshTokenRequest {
  refreshToken: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}
export interface RegisterRequest {
  name: string;
  email: string;
  phoneNumber: string;
  password: string;
  country: string;
  hasGivenETKConsents: boolean;
}

export interface RegisterState extends RegisterRequest {
  _surname: string;
  _kvkk: boolean;
  _terms: boolean;
}

export interface ChangePasswordRequest {
  oldPassword: string;
  newPassword: string;
}

export interface ChangePasswordState extends ChangePasswordRequest {
  _newPasswordVerify: string;
}

export enum PasswordResetCodeState {
  HIDDEN = 'HIDDEN',
  SHOWN = 'SHOWN',
}

export interface ForgotPasswordRequest {
  email: string;
  phoneNumber: string;
  passwordResetCode?: string;
  newPassword: string;
}

export interface ForgotPasswordState extends ForgotPasswordRequest {
  passwordResetCodeState: PasswordResetCodeState;
}

export interface IsUserVerifiedOTPResponse {
  mailVerified: boolean;
  phoneVerified: boolean;
}

export interface OTPVerificationRequest {
  verificationCode: string;
}

export interface OnModeChangeParams
  extends Omit<AuthModalIndividualProps, 'onModeChange'> {
  mode: AuthModalMode;
  userInfo?: UserInfo | null;
}

export interface AuthModalIndividualProps {
  email?: string;
  phoneNumber?: string;
  password?: string;
  onModeChange: (params: OnModeChangeParams) => void;
}

export interface IsUserVerifiedOTPResponse {
  mailVerified: boolean;
  phoneVerified: boolean;
}

export interface OTPVerificationRequest {
  verificationCode: string;
}

export interface UserInfo {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  country: string;
  sinceLastPurchase: string;
  sinseLastSession: string;
}

export interface TokenWithUserInfo extends TokensResponse {
  userInfo: UserInfo;
}

export interface FullNameProviderResponse {
  givenName: string;
  familyName: string;
}
export interface AppleProviderResponse {
  identityToken: string;
  email: string;
  fullName: FullNameProviderResponse;
}

export interface GoogleProviderResponse {
  idToken: string;
  user: FullNameProviderResponse;
}

export type SocialSignInPayload =
  | { type: SocialLoginType.APPLE; providerResponse: AppleProviderResponse }
  | { type: SocialLoginType.GOOGLE; providerResponse: GoogleProviderResponse }
  | null;
