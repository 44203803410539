import FacebookIcon from 'components/Icons/socials/facebook.svg';
import InstagramIcon from 'components/Icons/socials/instagram.svg';
import LinkedInIcon from 'components/Icons/socials/linkedin.svg';
import PinterestIcon from 'components/Icons/socials/pinterest.svg';
import TikTokIcon from 'components/Icons/socials/tiktok.svg';
// import TwitterIcon from 'components/Icons/socials/twitter.svg';
import WhatsAppIcon from 'components/Icons/socials/whatsapp.svg';
import YoutubeIcon from 'components/Icons/socials/youtube.svg';
import { SocialMediaLinks } from 'enums/SocialMediaLinks';
import useTranslation from 'next-translate/useTranslation';

function SocialNetworks() {
  const { t } = useTranslation('common');
  const socialNetworks = [
    {
      title: 'Facebook',
      icon: FacebookIcon,
      link: SocialMediaLinks.FACEBOOK,
    },
    {
      title: 'Instagram',
      icon: InstagramIcon,
      link: SocialMediaLinks.INSTAGRAM,
    },
    // {
    //   title: 'Twitter',
    //   icon: TwitterIcon,
    //   link: SocialMediaLinks.TWITTER,
    // },
    {
      title: 'LinkedIn',
      icon: LinkedInIcon,
      link: SocialMediaLinks.LINKEDIN,
    },
    {
      title: 'Youtube',
      icon: YoutubeIcon,
      link: SocialMediaLinks.YOUTUBE,
    },
    {
      title: 'TikTok',
      icon: TikTokIcon,
      link: SocialMediaLinks.TIKTOK,
    },
    {
      title: 'Pinterest',
      icon: PinterestIcon,
      link: SocialMediaLinks.PINTEREST,
    },
  ];

  return (
    <div className="mt-12 flex items-center justify-center space-x-8 bg-stone-50 py-12 px-2">
      {socialNetworks.map((socialNetwork) => (
        <a
          href={socialNetwork.link}
          target="_blank"
          title={socialNetwork.title}
          rel="nofollow noreferrer noopener"
          key={socialNetwork.link}
        >
          {socialNetwork.icon({ className: 'h-5' })}
        </a>
      ))}
      <a
        rel="nofollow noreferrer noopener"
        title="WhatsApp"
        target="_blank"
        href={t('whatsappLink')}
      >
        <WhatsAppIcon className="h-5" />
      </a>
    </div>
  );
}

export default SocialNetworks;
