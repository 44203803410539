import BButton from 'components/common/BButton';
import BNotifBadge from 'components/common/BNotfiBadge';
import CdnSvg from 'components/common/CdnSvg';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent, useState } from 'react';
import { useNotificationState } from 'states/useNotificationState';
import styles from 'styles/Layout.module.css';
import { cn } from 'utils/cn';
import LocalizationDropdown from './dropdowns/LocalizationDropdown';
import UserDropdown from './dropdowns/UserDropdown';

interface LocalizationAndUserDropdownProps {
  intersecting: boolean;
}

const LocalizationAndUserDropdown: FunctionComponent<
  LocalizationAndUserDropdownProps
> = ({ intersecting }) => {
  const { t } = useTranslation('navbar');
  const [userDropdown, setUserDropdown] = useState<boolean>(false);
  const [localizationDropdown, setLocalizationDropdown] =
    useState<boolean>(false);
  const { getOffersPageBadges, getReservationsPageBadges } =
    useNotificationState();

  const isBadgeVisible =
    getOffersPageBadges() + getReservationsPageBadges() > 0;

  return (
    <div className="relative hidden items-center justify-end md:flex">
      <div className="relative flex items-center">
        <BButton
          priority="none"
          title={t('linkTitles.localizationSettings')}
          aria-expanded={localizationDropdown ? 'true' : 'false'}
          onClick={() => setLocalizationDropdown(true)}
        >
          <CdnSvg
            prioritized
            className={cn(styles.languageSelector, { hidden: intersecting })}
            src="globe.svg"
          />
          <CdnSvg
            prioritized
            className={cn(styles.languageSelector, { hidden: !intersecting })}
            src="globe-white.svg"
          />
        </BButton>
        <LocalizationDropdown
          show={localizationDropdown}
          onClose={() => setLocalizationDropdown(false)}
        />
      </div>
      <BButton
        priority="none"
        className={cn(styles.userContainer, 'ml-5')}
        onClick={() => setUserDropdown(true)}
        aria-expanded={userDropdown ? 'true' : 'false'}
        title={t('linkTitles.userActions')}
      >
        <CdnSvg
          prioritized
          className={cn(styles.menuIcon, { hidden: intersecting })}
          src="hamburger.svg"
        />
        <CdnSvg
          prioritized
          className={cn(styles.menuIcon, { hidden: !intersecting })}
          src="hamburger-white.svg"
        />
        <CdnSvg
          prioritized
          className={cn(styles.userIcon, 'ml-3', { hidden: intersecting })}
          src="user.svg"
        />
        <CdnSvg
          prioritized
          className={cn(styles.userIcon, 'ml-3', { hidden: !intersecting })}
          src="user-white.svg"
        />
      </BButton>
      <UserDropdown
        show={userDropdown}
        onClose={() => setUserDropdown(false)}
      />
      {isBadgeVisible ? (
        <BNotifBadge className="absolute -top-0.5 -right-0.5" />
      ) : null}
    </div>
  );
};

export default LocalizationAndUserDropdown;
