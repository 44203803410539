import React, { FunctionComponent } from 'react';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import tr from 'react-phone-input-2/lang/tr.json';
import { useRouter } from 'next/router';
import classNames from 'classnames';

export type BPhoneInputProps = PhoneInputProps;

const BPhoneInput: FunctionComponent<PhoneInputProps> = ({
  country,
  disabled,
  ...props
}) => {
  const { locale } = useRouter();

  return (
    <PhoneInput
      localization={locale === 'tr' ? tr : undefined}
      disabled={disabled}
      country={country || 'tr'}
      countryCodeEditable={false}
      buttonClass={disabled ? 'cursor-not-allowed' : ''}
      containerClass={classNames(
        'border border-primary-400 rounded w-full shadow-md',
        {
          'cursor-not-allowed border-slate-300 bg-slate-50': disabled,
        }
      )}
      inputClass="px-4 py-2 w-full text-base h-auto disabled:!bg-slate-50"
      {...props}
    />
  );
};

export default BPhoneInput;
