import classNames from 'classnames';
import { useComponentMounted } from 'hooks/useComponentMounted';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { getWindowScrollTop } from 'utils/getWindowScrollTop';

interface CookieBannerProps {
  className: string;
}

const CookieBanner: FunctionComponent<CookieBannerProps> = ({ className }) => {
  const { t } = useTranslation('common');
  const localStorageKey = 'cookie_banner';
  const [show, setShow] = useState<boolean>(false);

  useComponentMounted(() => {
    if (typeof window !== 'undefined') {
      const key = window.localStorage.getItem(localStorageKey);
      setShow(!key);
    }
  });

  const closeBanner = useCallback(() => {
    if (getWindowScrollTop() > 0) {
      window.localStorage.setItem(localStorageKey, 'accepted');
      setShow(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', closeBanner);
    return () => document.removeEventListener('scroll', closeBanner);
  }, []);

  return show ? (
    <div
      className={classNames(
        'fixed bottom-[var(--bottom-navigation-menu-height)] z-100 w-full bg-slate-100 text-center opacity-90 md:bottom-0',
        className
      )}
    >
      <div className="tkv-container mx-auto flex items-center justify-center gap-2">
        <div className="p-2 text-sm text-slate-600">
          {t('cookieBannerText')}
        </div>
      </div>
    </div>
  ) : null;
};

export default CookieBanner;
