import BButton from 'components/common/BButton';
import useTranslation from 'next-translate/useTranslation';
import { FormEvent, FunctionComponent, useRef } from 'react';
import { AuthModalIndividualProps, UserInfo } from 'types/auth.type';
import { useForm } from 'hooks/useForm';
import BLabelWrapper from 'components/Form/BLabelWrapper';
import BPhoneInput from 'components/Form/BPhoneInput';
import { CountryData } from 'react-phone-input-2';
import {
  phoneNumberValidation,
  validateSchema,
} from 'validation/auth.validation';
import { profileService } from 'services/ProfileService';
import { useUserState } from 'states/useUserState';
import { toast } from 'react-toastify';
import BCheckbox from 'components/Form/BCheckbox';
import { useLocale } from 'hooks/useLocale';
import { Locale } from 'enums/locale';
import { noop } from 'utils/noop';
import { firebaseAuthService } from 'services/FirebaseAuthService';

interface PhoneNumberFormProps extends AuthModalIndividualProps {
  userInfo: UserInfo | null;
}

const PhoneNumberForm: FunctionComponent<PhoneNumberFormProps> = ({
  phoneNumber,
  userInfo,
}) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { setIsUserLoggedIn } = useUserState();
  const kvkkRef = useRef<HTMLDivElement>(null);
  const termsRef = useRef<HTMLDivElement>(null);

  const defaultState = {
    phoneNumber: phoneNumber || '',
    country: '',
    hasGivenETKConsents: false,
    _kvkk: false,
    _terms: false,
  };

  const [state, dispatch] = useForm(defaultState);

  const validateState = (): boolean => {
    const errors: string[] = [];
    if (!state._kvkk) {
      toast.error(t('navbar:agreements.kvkkAndConsent.error'));
      errors.push('_kvkk');
      kvkkRef.current?.classList.add('text-red-500');
    }

    if (!state._terms) {
      toast.error(t('navbar:agreements.terms.error'));
      errors.push('_terms');
      termsRef.current?.classList.add('text-red-500');
    }

    if (errors.length === 0) {
      kvkkRef.current?.classList.remove('text-red-500');
      termsRef.current?.classList.remove('text-red-500');
      return true;
    }
    return false;
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateState()) {
      return;
    }

    const schema = {
      phoneNumber: phoneNumberValidation,
    };

    const token = firebaseAuthService.getTokens();
    const isValid = await validateSchema(schema, state, t, noop);

    if (isValid && token) {
      await profileService.updateProfile(
        {
          address: null,
          dateOfBirth: null,
          name: userInfo?.firstName || '',
          spokenLanguages: [],
          surname: userInfo?.lastName || '',
          phoneNumber: `+${state.phoneNumber}`,
          country: state.country,
          initalLogin: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        }
      );
      // analyticsService.PhoneNumberForm();
      toast.success(t('common:auth.phoneNumberForm.success'), {
        toastId: 'PhoneNumberFormSuccess',
      });
      firebaseAuthService.setTokens();
      setIsUserLoggedIn();
    }
  };

  const redirectToKvkk = () => {
    const link =
      locale === Locale.TR
        ? '/legal/kvkk-aydinlatma/'
        : '/en/legal/kvkk-clarification/';
    window.open(link, '_blank');
  };

  const redirectToTerms = () => {
    const link =
      locale === Locale.TR
        ? '/legal/kullanim-sartlari/'
        : '/en/legal/terms-of-use/';
    window.open(link, '_blank');
  };

  const redirectToOpenConsent = () => {
    const link =
      locale === Locale.TR ? '/legal/acik-riza/' : '/en/legal/open-consent/';
    window.open(link, '_blank');
  };

  const redirectToETK = () => {
    const link = locale === Locale.TR ? '/legal/etk/' : '/en/legal/etk/';
    window.open(link, '_blank');
  };

  return (
    <div>
      <form onSubmit={onSubmit} noValidate>
        <div className="mb-3 max-w-md">
          {t('common:auth.phoneNumberForm.success')}
        </div>
        <BLabelWrapper label={t('navbar:phoneNumber')} wrapperClassName="mb-6">
          <BPhoneInput
            value={state.phoneNumber}
            onChange={(value, country) => {
              dispatch({ type: 'phoneNumber', payload: `${value}` });
              dispatch({
                type: 'country',
                payload: `${(country as CountryData).name}`,
              });
            }}
          />
        </BLabelWrapper>
        <div className="mb-6 flex flex-col gap-4">
          <BCheckbox
            checked={state._kvkk}
            onClick={() =>
              dispatch({
                type: '_kvkk',
                payload: !state._kvkk,
              })
            }
            label={
              <div className="mx-auto max-w-md" ref={kvkkRef}>
                <span>{t('navbar:agreements.kvkkAndConsent.start')} </span>
                <span
                  className="underline hover:no-underline"
                  onClick={redirectToKvkk}
                  role="button"
                  tabIndex={0}
                >
                  {t('navbar:agreements.kvkkAndConsent.kvkk')}
                </span>{' '}
                <span>{t('navbar:agreements.kvkkAndConsent.middle')} </span>
                <span
                  className="underline hover:no-underline"
                  onClick={redirectToOpenConsent}
                  role="button"
                  tabIndex={0}
                >
                  {t('navbar:agreements.kvkkAndConsent.openConsent')}
                </span>{' '}
                <span>{t('navbar:agreements.kvkkAndConsent.end')}</span>
              </div>
            }
          />
          <BCheckbox
            checked={state.hasGivenETKConsents}
            onClick={() =>
              dispatch({
                type: 'hasGivenETKConsents',
                payload: !state.hasGivenETKConsents,
              })
            }
            label={
              <div className="mx-auto max-w-md">
                <span>{t('navbar:agreements.etk.start')} </span>
                <span
                  className="underline hover:no-underline"
                  onClick={redirectToETK}
                  role="button"
                  tabIndex={0}
                >
                  {t('navbar:agreements.etk.link')}
                </span>{' '}
                <span>{t('navbar:agreements.etk.end')}</span>
              </div>
            }
          />
          <BCheckbox
            checked={state._terms}
            onClick={() =>
              dispatch({
                type: '_terms',
                payload: !state._terms,
              })
            }
            label={
              <div className="mx-auto max-w-md" ref={termsRef}>
                <span>{t('navbar:agreements.terms.start')} </span>
                <span
                  className="underline hover:no-underline"
                  onClick={redirectToTerms}
                  role="button"
                  tabIndex={0}
                >
                  {t('navbar:agreements.terms.link')}
                </span>{' '}
                <span>{t('navbar:agreements.terms.end')}</span>
              </div>
            }
          />
        </div>
        <BButton fluid type="submit">
          {t('navbar:signInAction')}
        </BButton>
      </form>
    </div>
  );
};

export default PhoneNumberForm;
