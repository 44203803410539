import { SocialLoginType } from 'enums/socialLoginType';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth';
import {
  AppleProviderResponse,
  GoogleProviderResponse,
  SocialSignInPayload,
  TokenWithUserInfo,
  UserInfo,
} from 'types/auth.type';
import { authService } from './AuthService';
import { cookieService } from './CookieService';

const firebaseConfig = {
  apiKey: 'AIzaSyDdGkl17myw0yaCICVEmVRE4R6Tu7ZHkuM',
  authDomain: 'teknevia-90fe6.firebaseapp.com',
  projectId: 'teknevia-90fe6',
  storageBucket: 'teknevia-90fe6.appspot.com',
  messagingSenderId: '641183678892',
  appId: '1:641183678892:web:b97b82b31210103dba3a09',
  measurementId: 'G-NQ7QWYKLYD',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

let tokenWithUserInfo: TokenWithUserInfo | null = null;

class FirebaseAuthService {
  async toggleGoogleSignIn(): Promise<SocialSignInPayload> {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
    provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    provider.addScope('openid');
    if (auth === null) return null;
    try {
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const { givenName, familyName } =
        firebaseAuthService.getNamesFromResult(result);

      if (!credential?.idToken) return null;

      const providerResponse: GoogleProviderResponse = {
        idToken: credential.idToken,
        user: {
          givenName,
          familyName,
        },
      };

      return {
        providerResponse,
        type: SocialLoginType.GOOGLE,
      };
    } catch (e) {
      console.log('google error', e);
      return null;
    }
  }

  async toggleAppleSignIn(): Promise<SocialSignInPayload> {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');

    const locale = cookieService.getCookie('NEXT_LOCALE', {}) as string;

    provider.setCustomParameters({
      // Localize the Apple authentication screen in French.
      locale: locale ? locale : 'tr',
    });
    if (auth === null) return null;
    try {
      const result = await signInWithPopup(auth, provider);
      const credential = OAuthProvider.credentialFromResult(result);
      const { givenName, familyName } =
        firebaseAuthService.getNamesFromResult(result);

      if (!credential?.idToken) return null;
      if (!result.user.email) return null;

      const providerResponse: AppleProviderResponse = {
        identityToken: credential.idToken,
        email: result.user.email,
        fullName: {
          givenName,
          familyName,
        },
      };

      return {
        providerResponse,
        type: SocialLoginType.APPLE,
      };
    } catch (e) {
      console.log('apple error', e);
      return null;
    }
  }

  isUserRegistered(user: UserInfo): boolean {
    return !!user.phoneNumber;
  }

  getNamesFromResult(result: UserCredential) {
    const name = (result.user.displayName || '').split(' ');

    return {
      givenName: name.slice(0, -1).join(' '),
      familyName: name[name.length - 1],
    };
  }

  async socialSignIn(type: SocialLoginType): Promise<TokenWithUserInfo> {
    const response = await {
      [SocialLoginType.GOOGLE]: firebaseAuthService.toggleGoogleSignIn,
      [SocialLoginType.APPLE]: firebaseAuthService.toggleAppleSignIn,
    }[type]();

    const authResponse = await authService.socialSignIn(response);
    tokenWithUserInfo = authResponse;
    return authResponse;
  }

  getTokens() {
    return tokenWithUserInfo;
  }

  setTokens() {
    if (tokenWithUserInfo !== null) {
      const { userInfo, ...tokens } = tokenWithUserInfo;
      authService.setTokensToCookie(tokens);
      authService.setUserInfo(userInfo);
    }
  }
}

export const firebaseAuthService = new FirebaseAuthService();
