import classNames from 'classnames';
import { forwardRef, InputHTMLAttributes } from 'react';
import formItemStyles from 'styles/BFormItem.module.css';

export type BInputProps = InputHTMLAttributes<HTMLInputElement>;

const BInput = forwardRef<HTMLInputElement, BInputProps>(
  ({ className, ...attrs }, ref) => {
    return (
      <input
        ref={ref}
        className={classNames(formItemStyles['b-form-item'], className)}
        {...attrs}
      />
    );
  }
);

BInput.displayName = 'BInput';
export default BInput;
