import { AxiosError, AxiosRequestConfig } from 'axios';
import { GetServerSidePropsContext } from 'next';
import { client, discordAlertServiceClient } from './axios';
import { logger } from './logger';
import { getServerSideUserAgent } from 'utils/getServerSideUserAgent';

type SsrRequest = <T>(
  url: string,
  ctx: GetServerSidePropsContext,
  options?: AxiosRequestConfig
) => Promise<T>;

export const ssrRequest: SsrRequest = async (url, ctx, options = {}) => {
  try {
    const { data } = await client.get(url, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${ctx.req.cookies['access_token']}`,
        'Accept-Language': ctx.locale || 'tr',
        ...getServerSideUserAgent(),
      },
    });
    return data;
  } catch (error) {
    let discordErrorPayload: unknown = {};
    if (error instanceof AxiosError) {
      discordErrorPayload = {
        message: error?.response?.data?.message,
        status: error.response?.status,
        stack: JSON.stringify(error?.response?.data?.details?.trace)?.substring(
          0,
          1000
        ),
      };
      if (!error?.response?.data) {
        discordErrorPayload = error.response;
      }
    } else {
      discordErrorPayload = error;
    }
    discordAlertServiceClient.post('/sf-ssr-error', {
      pagePath: ctx.req.url,
      apiPath: url,
      error: JSON.stringify({
        referrer: ctx.req.headers.referer,
        ...(discordErrorPayload as object),
      }),
      env: process.env.NEXT_PUBLIC_APP_ENV,
      userId: ctx.req.cookies['access_token'],
    });
    logger.error({
      application: 'Storefront SSR',
      pathname: url,
      error: JSON.stringify(error),
      message: `SSR Request Error : ${
        ((error as AxiosError)?.response as any).data.message
      }`,
    });
    return null;
  }
};
