import Image, { ImageLoaderProps, ImageProps } from 'next/image';
import { FunctionComponent } from 'react';

const BImage: FunctionComponent<ImageProps> = (props) => {
  const { layout = 'fixed', priority, ...restProps } = props;
  const imageLoader = ({ src }: ImageLoaderProps) => {
    let url = src;
    url += '?format=auto';
    url += `&quality=${props.quality ? props.quality : '75'}`;
    if (layout === 'fixed') {
      url += `&width=${props.width}`;
      url += `&height=${props.height}`;
    }
    return url;
  };

  return (
    <Image
      {...restProps}
      layout={layout}
      alt={props.alt}
      loader={imageLoader}
      loading={priority ? 'eager' : 'lazy'}
      priority={priority}
    />
  );
};

export default BImage;
