import { AuthModalMode } from 'enums/authModalMode';
import { NonFunctionStatePropertyNames } from 'types/state.type';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface AuthModalState {
  showAuthModal: boolean;
  setShowAuthModal: (val: AuthModalState['showAuthModal']) => void;
  authMode: AuthModalMode;
  setAuthMode: (val: AuthModalState['authMode']) => void;
}

export const useAuthModalState = create<AuthModalState>()(
  devtools((set) => {
    const setter =
      (key: NonFunctionStatePropertyNames<AuthModalState>) =>
      (val: AuthModalState[typeof key]) =>
        set((state) => ({ ...state, [key]: val }));
    return {
      showAuthModal: false,
      setShowAuthModal: setter('showAuthModal'),
      authMode: AuthModalMode.LOGIN,
      setAuthMode: setter('authMode'),
    };
  })
);
