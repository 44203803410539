import { searchService } from 'services/SearchService';
import {
  FilterOptions,
  FilterParameters,
  FilterState,
} from 'types/search.type';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface FilterStore {
  filterOptions: FilterOptions | null;
  getFilterOptions: () => Promise<FilterOptions>;
  filterParams: FilterParameters;
  setFilterParams: (val: FilterState) => void;
  cleanFilterParams: () => void;
}

export const useFilterStore = create<FilterStore>()(
  devtools((set, get) => {
    return {
      filterOptions: null,
      async getFilterOptions() {
        const filterOptions = get().filterOptions;
        if (filterOptions !== null) {
          return filterOptions;
        }
        const res = await searchService.getFilterOptions();
        set((state) => ({
          ...state,
          filterOptions: res,
        }));
        return res;
      },
      filterParams: {},
      setFilterParams: (val) =>
        set((state) => {
          const filterParams: FilterParameters =
            searchService.mapFilterStateToServerDto(val);
          return {
            ...state,
            filterParams,
          };
        }),
      cleanFilterParams: () => {
        set((state) => {
          return {
            ...state,
            filterParams: {},
          };
        });
      },
    };
  })
);
