import { NotificationType } from 'enums/notificationTypes';
import { client } from 'utils/axios';

class NotificationService {
  async getNotifications(): Promise<Record<NotificationType, number>> {
    const { data } = await client.get('/v1/customer/notification/badge');
    return data;
  }

  async deleteNotifications(
    notificationType: NotificationType[]
  ): Promise<void> {
    await client.put(`/v1/customer/notification/badge`, notificationType);
  }
}

export const notificationService = new NotificationService();
