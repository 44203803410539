import { cmsClient } from 'utils/axios';

class RemoteConfigService {
  async getConfigs() {
    const webConfigId = process.env.NEXT_PUBLIC_APP_ENV === 'prod' ? 3 : 5;
    return (await cmsClient.get(`/app-configs/${webConfigId}`)).data.config;
  }
}

export const remoteConfigService = new RemoteConfigService();
