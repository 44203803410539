import classNames from 'classnames';
import BNotifBadge from 'components/common/BNotfiBadge';
import CdnSvg from 'components/common/CdnSvg';
import { FunctionComponent } from 'react';
import { BottomNavigationMenuItem } from 'types/layout.type';
import BottomMenuItemWrapper from './BottomMenuItemWrapper';

interface BottomMenuItemProps {
  menuItem: BottomNavigationMenuItem;
  variant?: 'seeMore' | 'bottomMenu';
  wrapperClass?: string;
  isActive?: boolean;
}

const BottomMenuItem: FunctionComponent<BottomMenuItemProps> = ({
  menuItem,
  variant = 'bottomMenu',
  wrapperClass,
  isActive = false,
}) => {
  return (
    <BottomMenuItemWrapper
      menuItem={menuItem}
      wrapperClass={wrapperClass}
      itemClass={variant === 'seeMore' ? 'w-full' : undefined}
    >
      {variant === 'bottomMenu' ? (
        <div
          className={classNames('relative flex flex-col items-center', {
            'text-primary-500': isActive,
          })}
        >
          <CdnSvg src={menuItem.icon} size={20} className="block" />
          <span className="mt-2 text-xs">{menuItem.label}</span>
          {menuItem.badge ? (
            <BNotifBadge className="absolute -top-2 -right-2">
              {menuItem.badge}
            </BNotifBadge>
          ) : null}
        </div>
      ) : (
        <div
          className={classNames('flex items-center gap-2', {
            'text-primary-500': isActive,
          })}
        >
          <CdnSvg src={menuItem.icon} size={28} className="h-7 w-7" />
          <span className="ml-2">{menuItem.label}</span>
        </div>
      )}
    </BottomMenuItemWrapper>
  );
};

export default BottomMenuItem;
