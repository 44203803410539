import { AuthModalMode } from 'enums/authModalMode';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAuthModalState } from 'states/useAuthModalState';
import { useModalState } from 'states/useModalState';
import { useNotificationState } from 'states/useNotificationState';
import { useUserState } from 'states/useUserState';
import { BottomNavigationMenuItem } from 'types/layout.type';
import BottomMenuItem from './BottomMenuItem';
import BottomMenuSeeMoreModal from './BottomMenuSeeMoreModal';
import { useRemoteConfigState } from 'states/useRemoteConfigState';
import { useLocale } from 'hooks/useLocale';
import { linkService } from 'services/LinkService';

const BottomNavigationBar: FunctionComponent = () => {
  const bottomNavigationBarHiddenMenus = useMemo(
    () => ['/tekneler/[slug]'],
    []
  );
  const { t } = useTranslation('navbar');
  const { pathname, events } = useRouter();
  const { isUserLoggedIn } = useUserState();
  const { removeStyles } = useModalState();
  const { showAuthModal, setAuthMode, setShowAuthModal } = useAuthModalState();
  const [isSeeMoreMenuOpen, setIsSeeMoreMenuOpen] = useState<boolean>(false);
  const { getOffersPageBadges } = useNotificationState();
  const { getConfig } = useRemoteConfigState();
  const isMessagingActive = getConfig('MESSAGING_ENABLED', true);
  const { locale } = useLocale();
  const actionLink = linkService.generateCategoryLink({ locale });

  const isBottomNavBarHidden = () => {
    return bottomNavigationBarHiddenMenus.some((p) => p === pathname);
  };

  const handleRouterChangeEvent = useCallback(() => {
    setIsSeeMoreMenuOpen(false);
    setShowAuthModal(false);
    removeStyles(0);
  }, []);

  useEffect(() => {
    events.on('routeChangeComplete', handleRouterChangeEvent);
    return () => events.off('routeChangeComplete', handleRouterChangeEvent);
  }, []);

  const showLoginPage = () => {
    setAuthMode(AuthModalMode.LOGIN);
    setShowAuthModal(!showAuthModal);
    setTimeout(() => {
      setIsSeeMoreMenuOpen(false);
    }, 100);
  };

  const toggleSeeMoreMenu = useCallback(() => {
    setIsSeeMoreMenuOpen((val) => !val);
    setTimeout(() => {
      setShowAuthModal(false);
    }, 100);
  }, []);

  const loggedInMenuContents: BottomNavigationMenuItem[] = [
    {
      label: t('offers'),
      href: '/tekliflerim',
      icon: 'envelope-open.svg',
      badge: getOffersPageBadges(),
    },
    {
      label: t('favorites'),
      href: '/favorilerim',
      icon: 'favourite-primary.svg',
    },
    ...(isMessagingActive
      ? [
          {
            label: t('messages'),
            href: '/message',
            icon: 'chat.svg',
          },
        ]
      : []),
  ];

  const loggedOutMenuContents: BottomNavigationMenuItem[] = [
    { label: t('login'), onClick: showLoginPage, icon: 'user-simple.svg' },
    ...(isMessagingActive
      ? [
          {
            label: t('bottomMenu.support'),
            href: t('common:whatsappLink'),
            isLinkOutside: true,
            icon: 'whatsapp-logo.svg',
          },
        ]
      : []),
  ];

  const menuContents: BottomNavigationMenuItem[] = [
    {
      label: t('bottomMenu.discover'),
      href: actionLink,
      icon: 'search-black.svg',
    },
    ...(isUserLoggedIn ? loggedInMenuContents : loggedOutMenuContents),
    {
      label: t('bottomMenu.seeMore'),
      onClick: toggleSeeMoreMenu,
      icon: 'plus.svg',
    },
    ...(!isMessagingActive
      ? [
          {
            label: t('bottomMenu.support'),
            href: t('common:whatsappLink'),
            isLinkOutside: true,
            icon: 'whatsapp-logo.svg',
          },
        ]
      : []),
  ];

  const isItemActive = (menuItem: BottomNavigationMenuItem): boolean => {
    if (showAuthModal) {
      return menuItem.label === t('login');
    }
    if (isSeeMoreMenuOpen) {
      return menuItem.label === t('bottomMenu.seeMore');
    }
    return menuItem.href === pathname;
  };

  return isBottomNavBarHidden() ? null : (
    <nav className="fixed bottom-0 left-0 z-400 block h-[var(--bottom-navigation-menu-height)] w-full border-t border-slate-100 bg-white shadow-lg">
      <ul className="my-2 flex justify-center gap-2">
        {menuContents.map((menuItem) => (
          <BottomMenuItem
            menuItem={menuItem}
            key={menuItem.label}
            isActive={isItemActive(menuItem)}
          />
        ))}
      </ul>
      <BottomMenuSeeMoreModal
        show={isSeeMoreMenuOpen}
        onClose={() => setIsSeeMoreMenuOpen(false)}
      />
    </nav>
  );
};

export default BottomNavigationBar;
