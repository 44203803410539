class EventBus {
  events = {
    SWIMMING_TOUR_CHANGE: 'swimmingTourChange',
    APP_PROMOTION_BANNER_CLOSE: 'appPromotionBannerClose',
  };

  dispatchEvent(eventType: EventTypes, params?: unknown) {
    if (typeof window !== undefined) {
      const event = new CustomEvent(eventType, { detail: params });
      window.dispatchEvent(event);
    }
  }

  addEventListener<T>(
    eventType: EventTypes,
    callback: (e: CustomEventInit<T>) => void
  ) {
    window.addEventListener(eventType, callback);
  }

  removeEventListener<T>(
    eventType: EventTypes,
    callback: (e: CustomEventInit<T>) => void
  ) {
    window.removeEventListener(eventType, callback);
  }
}

export const eventBus = new EventBus();

export type EventTypes = EventBus['events'][keyof EventBus['events']];
