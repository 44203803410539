// import { GetServerSidePropsContext } from 'next';
import { DAY_IN_SECONDS, MINUTE_IN_SECONDS } from 'enums/durations';
import { cookieService } from 'services/CookieService';
import {
  ChangePasswordRequest,
  ForgotPasswordRequest,
  IsUserVerifiedOTPResponse,
  LoginRequest,
  OTPVerificationRequest,
  RefreshTokenRequest,
  RegisterRequest,
  SocialSignInPayload,
  TokensResponse,
  TokenWithUserInfo,
  UserInfo,
} from 'types/auth.type';
import { client } from 'utils/axios';

class AuthService {
  userVerifiedCookieKey = 'is_user_verified';
  userInfoCookieKey = 'user-info';
  async login(payload: LoginRequest) {
    const { data } = await client.post<TokenWithUserInfo>(
      '/v1/customer/auth/login',
      payload
    );
    this.setTokensToCookie(data);
    this.setUserInfo(data.userInfo);
  }

  async register(payload: RegisterRequest) {
    const { data } = await client.post<TokenWithUserInfo>(
      '/v1/customer/auth/register',
      payload
    );
    this.setTokensToCookie(data);
    this.setUserInfo(data.userInfo);
  }

  async socialSignIn(payload: SocialSignInPayload): Promise<TokenWithUserInfo> {
    const { data } = await client.post<TokenWithUserInfo>(
      '/v1/customer/auth/social/sign-in',
      payload
    );
    return data;
  }

  async refreshToken(payload?: RefreshTokenRequest) {
    try {
      const { refreshToken } = payload?.refreshToken
        ? payload
        : this.getTokensFromCookie();
      const { data } = await client.post<TokenWithUserInfo>(
        '/v1/customer/auth/refresh-token',
        {
          refreshToken: refreshToken,
        }
        // { hideSpinner: true, hideError: true }
      );

      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async changePassword(payload: ChangePasswordRequest): Promise<void> {
    await client.post('/v1/customer/auth/change-password', payload);
  }

  setUserVerifiedToCookie(options = {}) {
    cookieService.setCookie(this.userVerifiedCookieKey, true, {
      ...options,
    });
  }

  getUserVerifiedToCookie(options = {}) {
    return cookieService.getCookie(this.userVerifiedCookieKey, options);
  }

  async forgotPassword(payload: ForgotPasswordRequest): Promise<string> {
    const { data } = await client.post(
      '/v1/customer/auth/forgot-password',
      payload
    );
    return data.message;
  }

  logout() {
    this.removeTokensInCookies();
    this.removeUserInfo();
  }

  // async deleteAccount(): Promise<void> {
  //   await client.delete('/v1/partner/auth/delete-account');
  // }

  setTokensToCookie(data: TokensResponse, options = {}) {
    const accessTokenOptions = { maxAge: 2 * MINUTE_IN_SECONDS, path: '/' };
    const refreshTokenOptions = { maxAge: 39 * DAY_IN_SECONDS, path: '/' };

    cookieService.setCookie('access_token', data.accessToken, {
      ...accessTokenOptions,
      ...options,
    });
    cookieService.setCookie('refresh_token', data.refreshToken, {
      ...refreshTokenOptions,
      ...options,
    });
  }

  removeTokensInCookies(options = {}) {
    cookieService.deleteCookie('access_token', options);
    cookieService.deleteCookie('refresh_token', options);
    this.removeOTPCookie();
  }

  getTokensFromCookie(options = {}): Partial<TokensResponse> {
    const cookies = cookieService.getCookies(options);
    const accessToken = cookies['access_token'];
    const refreshToken = cookies['refresh_token'];

    return {
      accessToken,
      refreshToken,
    };
  }

  async isUserVerifiedOTP(): Promise<boolean> {
    const { data } = await client.get<IsUserVerifiedOTPResponse>(
      '/v1/customer/auth/otp/is-verified'
    );
    const isUserVerified = data.mailVerified || data.phoneVerified;
    this.setOTPCookie(isUserVerified);
    return isUserVerified;
  }

  getOTPCookie() {
    return cookieService.getCookie(this.userVerifiedCookieKey, {});
  }

  setOTPCookie(val: boolean) {
    cookieService.setCookie(this.userVerifiedCookieKey, val, {});
  }

  removeOTPCookie() {
    cookieService.deleteCookie(this.userVerifiedCookieKey, {});
  }

  async sendOTP(): Promise<string> {
    const { data } = await client.get('/v1/customer/auth/otp/verification');
    return data.message;
  }

  async verifyOTP(payload: OTPVerificationRequest): Promise<string> {
    const { data } = await client.post('/v1/customer/auth/otp/verify', payload);
    return data.message;
  }

  setUserInfo(userInfo: UserInfo, options = {}) {
    cookieService.setCookie(
      this.userInfoCookieKey,
      JSON.stringify(userInfo),
      options
    );
  }

  getUserInfo(options = {}) {
    const userInfo = cookieService.getCookie(this.userInfoCookieKey, options);
    if (userInfo) {
      return JSON.parse(
        cookieService.getCookie(this.userInfoCookieKey, options) as string
      ) as UserInfo;
    }
    return null;
  }

  removeUserInfo(options = {}) {
    cookieService.deleteCookie(this.userInfoCookieKey, options);
  }
}

export const authService = new AuthService();
