import { AxiosError, AxiosInstance } from 'axios';
import { AuthModalMode } from 'enums/authModalMode';
import { Locale } from 'enums/locale';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import { authService } from 'services/AuthService';
import { cookieService } from 'services/CookieService';
import { useAuthModalState } from 'states/useAuthModalState';
import { useOTPModalState } from 'states/useOTPModal';
import { useUserState } from 'states/useUserState';
import { client as apiClient } from 'utils/axios';
import { useLocale } from './useLocale';

export const useInterceptors = () => {
  const isInjected = useRef<boolean>(false);
  const { locale } = useLocale();
  const { setIsUserLoggedIn } = useUserState();
  const { setAuthMode, setShowAuthModal } = useAuthModalState();
  const { setShowOTPModal } = useOTPModalState();

  if (!isInjected.current && typeof window !== 'undefined') {
    for (const c of [apiClient]) {
      injectRequestInterceptors(c, { locale });
      injectResponseInterceptors(c, {
        setIsUserLoggedIn,
        setAuthMode,
        setShowAuthModal,
        setShowOTPModal,
      });
    }
    isInjected.current = true;
  }
};

const injectResponseInterceptors = (
  client: AxiosInstance,
  {
    setIsUserLoggedIn,
    setAuthMode,
    setShowAuthModal,
    setShowOTPModal,
  }: {
    setIsUserLoggedIn: () => void;
    setAuthMode: (mode: AuthModalMode) => void;
    setShowAuthModal: (val: boolean) => void;
    setShowOTPModal: (val: boolean) => void;
  }
) => {
  client.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          if (error.response.data?.details?.otpRequired) {
            setShowOTPModal(true);
            return Promise.reject(error);
          }
          const url = error.config?.url || '';

          const isAuthApi = [
            '/v1/customer/auth/login',
            '/v1/customer/auth/otp/verify',
            '/v1/customer/auth/register',
            '/v1/customer/auth/forgot-password',
          ].includes(url);

          if (!isAuthApi) {
            authService.logout();
            setIsUserLoggedIn();
            setAuthMode(AuthModalMode.LOGIN);
            setShowAuthModal(true);
            return Promise.reject(error);
          }
        }
        if (!error.config?.hideError) {
          const locale = cookieService.getCookie('NEXT_LOCALE', {}) as Locale;
          const genericError =
            locale === 'tr'
              ? 'İşlem sırasında bir hata oluştu. Lütfen tekrar deneyiniz.'
              : 'An error occurred during the operation. Please try again.';
          toast.error(error.response?.data?.message ?? genericError, {
            toastId: 'apiError',
          });
        }
      }
      return Promise.reject(error);
    }
  );
};

const injectRequestInterceptors = (
  client: AxiosInstance,
  { locale }: { locale: Locale }
) => {
  client.interceptors.request.use(
    async (config) => {
      if (config.url?.includes('refresh-token')) {
        return config;
      }
      let { accessToken, refreshToken } = authService.getTokensFromCookie();
      if (!accessToken && refreshToken) {
        try {
          const data = await authService.refreshToken({ refreshToken });
          accessToken = data.accessToken;
          refreshToken = data.refreshToken;
          authService.setTokensToCookie(data);
          authService.setUserInfo(data.userInfo);
        } catch {
          authService.removeTokensInCookies();
          authService.removeUserInfo();
        }
      }

      config.headers = config.headers || {};
      if (accessToken && config?.headers && !config.headers['Content-Type']) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      config.headers['Accept-Language'] =
        cookieService.getCookie('NEXT_LOCALE', {}) || locale;
      config.headers['x-client-type'] = 'WEB';

      return config;
    },
    (error) => Promise.reject(error)
  );
};
