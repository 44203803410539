import Dropdown from 'components/common/Dropdown';
import DropdownClickableItem from 'components/common/DropdownClickableItem';
import { Locale } from 'enums/locale';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { Fragment, FunctionComponent } from 'react';
import { localizationService } from 'services/LocalizationService';
import { DropdownItem, DropdownProps } from 'types/dropdown.type';

interface DropdownItemWithValue<T> extends DropdownItem {
  value: T;
  link: string;
}

const LocalizationDropdown: FunctionComponent<DropdownProps> = ({
  show,
  onClose,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  // const {
  //   currency: stateCurrency,
  //   setCurrency: setStateCurrency } =
  //   useCurrencyState();

  const closedClick = (cb: () => void) => {
    return () => {
      cb();
      onClose?.();
    };
  };

  // const setCurrency = (currency: Currency) => {
  //   setStateCurrency(currency);
  //   localizationService.setCurrency(currency);
  // };

  // const currencyItems: DropdownItemWithValue<Currency>[] = [
  //   {
  //     label: '₺ TRY',
  //     value: Currency.TRY,
  //     action: () => setCurrency(Currency.TRY),
  //   },
  //   {
  //     label: '$ USD',
  //     value: Currency.USD,
  //     action: () => setCurrency(Currency.USD),
  //   },
  //   {
  //     label: '€ EUR',
  //     value: Currency.EUR,
  //     action: () => setCurrency(Currency.EUR),
  //   },
  // ];

  const getLocaleLink = (locale: Locale) => {
    return localizationService.getTranslatedRouteWithSlugs(router, locale);
  };

  const languageItems: DropdownItemWithValue<Locale>[] = [
    {
      label: 'Türkçe',
      value: Locale.TR,
      link: getLocaleLink(Locale.TR),
      action: () => localizationService.setServicesLocales(Locale.TR),
    },
    {
      label: 'English',
      value: Locale.EN,
      link: getLocaleLink(Locale.EN),
      action: () => localizationService.setServicesLocales(Locale.EN),
    },
  ];

  return (
    <Dropdown show={show} onClose={onClose}>
      <>
        <div className="px-2 py-1 font-medium text-primary-500">
          {t('navbar:language')}
        </div>
        {languageItems.map((item) => (
          <Fragment key={item.label}>
            {router.locale !== item.value ? (
              <a href={item.link}>
                <DropdownClickableItem
                  onClick={closedClick(item.action)}
                  isActive={router.locale === item.value}
                >
                  {item.label}
                </DropdownClickableItem>
              </a>
            ) : (
              <DropdownClickableItem
                onClick={closedClick(item.action)}
                isActive={router.locale === item.value}
              >
                {item.label}
              </DropdownClickableItem>
            )}
          </Fragment>
        ))}
        {/* <div className="px-2 py-1 text-primary-500 font-medium border-t">
          {t('navbar:currency')}
        </div>
        {currencyItems.map((item) => (
          <DropdownClickableItem
            key={item.label}
            onClick={closedClick(item.action)}
            isActive={stateCurrency === item.value}
          >
            {item.label}
          </DropdownClickableItem>
        ))} */}
      </>
    </Dropdown>
  );
};

export default LocalizationDropdown;
