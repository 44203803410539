import { Locale } from 'enums/locale';
import { NextRouter } from 'next-translate-routes';
import { localizationService } from 'services/LocalizationService';

export function getAlternateHref(router: NextRouter) {
  const locales = router.locales || [];
  const routerAsPath = router.asPath;
  const routerLocale = router.locale as Locale;
  const pathname = router.pathname;

  return locales
    .map((l) => {
      const locale = l as Locale;

      const translatedAsPath = getTranslatedAsPath({
        locale,
        pathname,
        routerLocale,
        routerAsPath,
      });

      let completeUrl = generateCompleteUrl({
        routerAsPath,
        translatedAsPath,
      });

      completeUrl = trimTrailingSlashIfExists(completeUrl);
      completeUrl = removeQueryParams(completeUrl);

      const links = [
        <link
          key={completeUrl}
          rel="alternate"
          hrefLang={l}
          href={completeUrl}
        />,
      ];

      if (l === routerLocale) {
        links.push(
          <link
            key={`${completeUrl}-cannonical`}
            rel="canonical"
            href={completeUrl}
          />
        );
      }

      return links;
    })
    .flat();
}

function getTranslatedAsPath({
  pathname,
  routerAsPath,
  locale,
  routerLocale,
}: {
  pathname: string;
  routerAsPath: string;
  locale: Locale;
  routerLocale: Locale;
}) {
  return localizationService.getTranslatedRouteWithSlugs(
    { pathname, asPath: routerAsPath, locale: routerLocale } as NextRouter,
    locale
  );
}

function generateCompleteUrl({
  translatedAsPath,
  routerAsPath,
}: {
  translatedAsPath: string;
  routerAsPath: string;
}) {
  return translatedAsPath
    ? `https://www.teknevia.com${translatedAsPath}`
    : `https://www.teknevia.com${routerAsPath}`;
}

function trimTrailingSlashIfExists(completeUrl: string) {
  return completeUrl.endsWith('/') ? completeUrl.slice(0, -1) : completeUrl;
}

function removeQueryParams(completeUrl: string) {
  return completeUrl.split('?')[0];
}
