export const MINUTE_IN_SECONDS = 60;
export const HOUR_IN_SECONDS = 3600;
export const DAY_IN_SECONDS = 24 * HOUR_IN_SECONDS;
export const WEEK_IN_SECONDS = 7 * DAY_IN_SECONDS;

export const DAY_IN_HOURS = 24;
export const THREE_DAY_IN_HOURS = 3 * DAY_IN_HOURS;
export const WEEK_IN_HOURS = 7 * DAY_IN_HOURS;
export const TEN_DAY_IN_HOURS = 10 * DAY_IN_HOURS;
export const ONE_MONTH_IN_HOURS = 30 * DAY_IN_HOURS;
