module.exports = {
  locales: ['en', 'tr'],
  defaultLocale: 'tr',
  localeDetection: false,
  pages: {
    '*': ['common', 'navbar', 'datepicker', '500'],
    '/': ['home'],
    '/tekneler/[slug]': ['vehicle'],
    '/gizlilik': ['privacy'],
    '/tekliflerim': ['demand'],
    '/rezervasyonlarim': ['demand'],
    '/payment/[id]': ['demand', 'vehicle', 'payment'],
    '/404': ['404'],
    '/partner': ['partner'],
    '/kisisel-bilgilerim': ['profile'],
    '/hizmetler': ['organizations'],
    '/hizmetler/[slug]': ['organizations'],
    '/[category]': ['search'],
    '/yardim-merkezi': ['helpCenter'],
    '/yardim-merkezi/[userTypeSlug]': ['helpCenter', 'search'],
    '/yardim-merkezi/[userTypeSlug]/[categorySlug]': ['helpCenter', 'search'],
    '/yardim-merkezi/[userTypeSlug]/[categorySlug]/[articleSlug]': [
      'helpCenter',
      'search',
    ],
    '/hakkimizda': ['about'],
    '/basinda-biz': ['press'],
    '/legal': ['legal'],
    '/favorilerim': ['favorites'],
    '/message': ['demand', 'message', 'vehicle'],
    '/kampanyalar': ['campaign'],
  },
};
