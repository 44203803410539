import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useIsMobileState } from 'states/useIsMobileState';
import { checkIsMobile } from 'utils/checkIsMobile';

export const Breakpoints = {
  NONE: 0,
  SM: 640,
  MD: 768,
  LG: 1024,
  XL: 1280,
  XXL: 1536,
};

export type keyOfBreakpoint = keyof typeof Breakpoints;

const mapBreakpointsByKeyToBool = (mediaQueryKey: string, offset: number) => {
  return (Object.keys(Breakpoints) as keyOfBreakpoint[]).reduce(
    (acc, key) => ({
      ...acc,
      [key]: useMediaQuery({
        [mediaQueryKey]: Breakpoints[key] + offset,
      }),
    }),
    {}
  ) as Record<keyOfBreakpoint, boolean>;
};

export const useBreakpoint = () => {
  const { isMobile, setIsMobile } = useIsMobileState();
  const min = mapBreakpointsByKeyToBool('minWidth', +1);
  const max = mapBreakpointsByKeyToBool('maxWidth', 0);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const uA =
        window.navigator.userAgent || window.navigator.vendor || window.opera;
      setIsMobile(checkIsMobile(uA));
    }
  }, [max.MD]);

  return {
    min,
    max,
    isMobile,
    setIsMobile,
    checkIsMobile,
  };
};
