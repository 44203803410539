import classNames from 'classnames';
import { FunctionComponent, LabelHTMLAttributes, ReactNode } from 'react';

export interface BLabelWrapperPropsWithoutNatives {
  label?: string;
  wrapperClassName?: string;
  labelClassName?: string;
}

interface BLabelWrapperProps extends BLabelWrapperPropsWithoutNatives {
  children: ReactNode;
  labelAttrs?: LabelHTMLAttributes<HTMLLabelElement>;
}

const BLabelWrapper: FunctionComponent<BLabelWrapperProps> = ({
  wrapperClassName = '',
  labelClassName = '',
  label = '',
  children,
  labelAttrs,
}) => {
  return (
    <label
      className={classNames('b-label-wrapper block', wrapperClassName)}
      {...labelAttrs}
    >
      {label ? (
        <span
          className={classNames(
            'mb-1 block text-sm md:text-base',
            labelClassName
          )}
        >
          {label}
        </span>
      ) : null}
      {children}
    </label>
  );
};

export default BLabelWrapper;
