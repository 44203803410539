import { Translate } from 'next-translate';
import { toast } from 'react-toastify';
import { object, string, ValidationError } from 'yup';
import { AnyObject } from 'yup/lib/object';
import StringSchema from 'yup/lib/string';

export const nameValidation = string()
  .required('navbar:name-required')
  .matches(/[A-Za-zğüşöçİĞÜŞÖÇ]+\s*/, 'navbar:name-matches');
export const surnameValidation = string()
  .required('navbar:surname-required')
  .matches(/[A-Za-zğüşöçİĞÜŞÖÇ]+\s*/, 'navbar:surname-matches');
export const emailValidation = string()
  .email('navbar:email-mask')
  .required('navbar:email-required');
export const phoneNumberValidation = string().required(
  'navbar:phoneNumber-required'
);
export const passwordValidation = string()
  .min(8, 'navbar:password-min')
  .required('navbar:password-required');

type ValidateSchemaType = <T>(
  schema: Record<string, StringSchema<string | undefined, AnyObject>>,
  state: T,
  t: Translate,
  successCb: () => void
) => Promise<boolean>;

export const validateSchema: ValidateSchemaType = async (
  schema,
  state,
  t,
  successCb
) => {
  const validationSchema = object(schema);

  try {
    await validationSchema.validate(state, {
      abortEarly: false,
    });

    successCb();
    return true;
  } catch (error) {
    if (error instanceof ValidationError) {
      error.inner.forEach((e: ValidationError) => {
        toast.error(t(e.message), { toastId: `authError-${e.name}` });
      });
    }
    return false;
  }
};
