import { Locale } from 'enums/locale';
import { Translate } from 'next-translate';

export default function vehicleNameCreator(
  brand: string,
  length: number,
  type: string,
  t: Translate
): string {
  if (brand === 'Özel yapım') {
    return `${t('common:customMadeBoatModel')} ${length}m ${type}`;
  }
  return `${brand} ${length}m ${type}`;
}

export function vehicleNameConverterForAnalytics(name: string): string {
  if (name.startsWith('Özel yapım')) {
    return name.replace('Özel yapım', 'Custom made');
  }
  return name;
}

export function vehicleNameCreatorSSR(
  brand: string,
  length: number,
  type: string,
  locale: Locale
): string {
  if (brand === 'Özel yapım') {
    return `${locale === Locale.TR ? brand : 'Custom made'} ${length}m ${type}`;
  }
  return `${brand} ${length}m ${type}`;
}
