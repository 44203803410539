import DropdownClickableItem from 'components/common/DropdownClickableItem';
import { BusinessModel } from 'enums/businessModel';
import useTranslation from 'next-translate/useTranslation';
import { Fragment, FunctionComponent } from 'react';
import { useSearchState } from 'states/useSearchState';
import { SearchState } from 'types/search.type';
import CdnSvg from 'components/common/CdnSvg';

interface SearchItemsProps {
  searchResults: SearchState[];
}

const SearchItems: FunctionComponent<SearchItemsProps> = ({
  searchResults,
}) => {
  const { t } = useTranslation('navbar');
  const {
    setSearchValue,
    setBusinessModel,
    setShowLocationDropdown,
    setShowDateDropdown,
    setSelectedLocation,
  } = useSearchState();

  const onClick = (loc: SearchState, parentSlug: string) => {
    if (parentSlug === 'istanbul' || loc.slug === 'istanbul' || !loc) {
      setBusinessModel(BusinessModel.HOURLY);
    } else {
      setBusinessModel(BusinessModel.DAILY_WEEKLY);
    }
    setSearchValue(loc.name);
    setSelectedLocation(loc);
    setShowLocationDropdown(false);
    setShowDateDropdown(true);
  };

  return (
    <>
      {searchResults.map((loc, idx) => (
        <Fragment key={`region-wrap-${idx}`}>
          <div className="sticky top-0" key={loc.slug.toLowerCase()}>
            <DropdownClickableItem
              wrapperClass={`font-semibold rounded-b-none bg-white ${
                loc.children?.length ? 'border-b border-primary-500' : ''
              }`}
              onClick={() => onClick(loc, loc.slug)}
            >
              <div className="flex items-center gap-3">
                <CdnSvg src="navigation-arrow.svg" size={16} />
                <div className="text-18px">{loc.name}</div>
                <div className="text-sm font-normal text-slate-500">
                  {t('search.location.vehicleCount', {
                    count: loc.vehicleCount,
                  })}
                </div>
              </div>
            </DropdownClickableItem>
          </div>
          {loc.children?.map((child) => (
            <div key={child.slug.toLowerCase()}>
              <DropdownClickableItem onClick={() => onClick(child, loc.slug)}>
                <div className="flex items-center gap-3">
                  <CdnSvg src="map-pin.svg" size={16} />
                  <div>
                    <div className="inline-block">{child.name}</div>
                    <div className="mt-1 text-sm text-slate-500">
                      {t('search.location.vehicleCount', {
                        count: child.vehicleCount,
                      })}
                    </div>
                  </div>
                </div>
              </DropdownClickableItem>
            </div>
          ))}
        </Fragment>
      ))}
      {searchResults.length === 0 ? (
        <DropdownClickableItem key="emptycase">
          {t('search.empty')}
        </DropdownClickableItem>
      ) : null}
    </>
  );
};

export default SearchItems;
