import BButton from 'components/common/BButton';
import BImage from 'components/common/BImage';
import CloseIcon from 'components/Icons/close.svg';
import { useBreakpoint } from 'hooks/useBreakpoints';
import { useFirstRender } from 'hooks/useFirstRender';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent, useEffect, useState } from 'react';
import { eventBus } from 'services/EventBus';

const AppPromotionBanner: FunctionComponent = () => {
  const { isMobile } = useBreakpoint();
  const { t } = useTranslation('common');
  const [show, setShow] = useState<boolean>(false);

  const redirectToAppLink = () => {
    if (typeof window !== 'undefined') {
      const UA = navigator.userAgent;
      const isWebkit =
        /\b(iPad|iPhone|iPod)\b/.test(UA) &&
        /WebKit/.test(UA) &&
        !/Edge/.test(UA) &&
        !window.MSStream;

      if (isWebkit) {
        window.open(
          'https://apps.apple.com/tr/app/teknevia-tekne-yat-kiralama/id1610580278',
          '_self'
        );
        return;
      }
      window.open(
        'https://play.google.com/store/apps/details?id=com.teknevia.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
        '_self'
      );
    }
  };

  useFirstRender(() => {
    isMobile && setShow(true);
  });

  useEffect(() => {
    setShow(isMobile);
  }, [isMobile]);

  const closeBanner = () => {
    setShow(false);
    eventBus.dispatchEvent(eventBus.events.APP_PROMOTION_BANNER_CLOSE);
  };

  return show ? (
    <div className="mobile-banner flex items-center justify-center">
      <div className="tkv-container flex h-20 w-full items-center gap-2">
        <BButton
          variant="secondary"
          priority="text"
          className="!p-0.5 !text-white hover:!bg-transparent"
          onClick={closeBanner}
          aria-label="close banner"
        >
          <CloseIcon className="h-4 w-4" />
        </BButton>
        <div className="relative h-10 w-10 shrink-0 overflow-hidden rounded-md shadow-md">
          <BImage
            alt="Teknevia"
            src="https://cdn.teknevia.com/storefront/common/mobile-logo-80.png"
            layout="fixed"
            height={40}
            width={40}
          />
        </div>
        <div className="text-medium w-40 text-xs text-white">
          {t('appPromotionBanner.desc')}
        </div>
        <BButton className="ml-auto text-xs" onClick={redirectToAppLink}>
          {t('appPromotionBanner.cta')}
        </BButton>
      </div>
    </div>
  ) : null;
};

export default AppPromotionBanner;
