import { FunctionComponent, InputHTMLAttributes, ReactNode } from 'react';
// import classNames from 'classnames';

export interface BCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  checked: boolean;
  onClick?: () => void;
  label: ReactNode;
}

const BCheckbox: FunctionComponent<BCheckboxProps> = ({
  label,
  onClick,
  checked,
  className,
  ...attrs
}) => {
  return (
    <>
      <label className={className}>
        <input
          className="shadow"
          checked={checked}
          onChange={onClick ? () => onClick() : undefined}
          {...attrs}
          type="checkbox"
        />
        <span className="text-sm md:text-base">{label}</span>
      </label>
      <style jsx>{`
        label {
          user-select: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 0.4rem;
        }
        input[type='checkbox'] {
          flex-shrink: 0;
          position: relative;
          -webkit-appearance: none;
          appearance: none;
          background-color: var(--clr-white);
          margin: 0;
          width: 1.3rem;
          height: 1.3rem;
          border: 1px solid var(--clr-primary-500);
          border-radius: 0.25rem;
          cursor: pointer;
        }
        input[type='checkbox']:disabled {
          background-color: var(--clr-gray-100);
          border: 1px solid var(--clr-gray-200);
          cursor: not-allowed;
        }
        input[type='checkbox']:disabled + span {
          color: var(--clr-gray-500);
        }
        input[type='checkbox']:checked {
          background-color: var(--clr-primary-500);
        }
        input[type='checkbox']::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0.5px;
          width: 1.1rem;
          height: 1.2rem;
          transform: scale(0);
          box-shadow: inset 1rem 1rem var(--clr-white);
        }
        input[type='checkbox']:checked::before {
          transform: scale(1);
          transform-origin: bottom left;
          clip-path: polygon(
            28% 38%,
            41% 53%,
            75% 24%,
            86% 38%,
            40% 78%,
            15% 50%
          );
        }
        span {
          display: inline-block;
        }
      `}</style>
    </>
  );
};

export default BCheckbox;
