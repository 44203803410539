import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface SpinnerStore {
  showSpinner: boolean;
  setShowSpinner: (val: boolean) => void;
}

export const useSpinnerState = create<SpinnerStore>()(
  devtools((set) => {
    return {
      showSpinner: false,
      setShowSpinner: (val) =>
        set((state) => {
          return {
            ...state,
            showSpinner: val,
          };
        }),
    };
  })
);
