import { NotificationType } from 'enums/notificationTypes';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface NotificationStore {
  badges: Partial<Record<NotificationType, number>>;
  getOffersPageBadges: () => number;
  getReservationsPageBadges: () => number;
  setBadges: (val: NotificationStore['badges']) => void;
  cleanupNotificationStore: () => void;
}

const cleanupState = (state: NotificationStore) => {
  return {
    ...state,
    badges: {},
  };
};

export const useNotificationState = create<NotificationStore>()(
  devtools((set, get) => {
    return {
      badges: {},
      getOffersPageBadges() {
        const badges = get().badges;
        const demand = badges.DEMAND ?? 0;
        const offer = badges.OFFER ?? 0;
        return demand + offer;
      },
      getReservationsPageBadges() {
        const badges = get().badges;
        return badges.RESERVATION ?? 0;
      },
      setBadges: (val) =>
        set((state) => {
          return {
            ...state,
            badges: val,
          };
        }),
      cleanupNotificationStore: () => set(cleanupState),
    };
  })
);
