import { keyOfBreakpoint, useBreakpoint } from 'hooks/useBreakpoints';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';

interface BreakpointProps {
  bp: keyOfBreakpoint;
  type: 'min' | 'max';
  children: ReactNode | ReactNode[];
}

const Breakpoint: FunctionComponent<BreakpointProps> = ({
  bp,
  type,
  children,
}) => {
  const breakpoints = useBreakpoint();
  const [isSSR, setIsSSR] = useState<boolean>(true);

  useEffect(() => {
    setIsSSR(false);
  }, []);

  return isSSR ? null : breakpoints[type][bp] ? <>{children}</> : null;
};

export default Breakpoint;
