// import ClientOnlyPortal from 'components/common/ClientOnlyPortal';
import Dropdown from 'components/common/Dropdown';
import DropdownClickableItem from 'components/common/DropdownClickableItem';
import { ReactNode, useRef } from 'react';
import formItemStyles from 'styles/BFormItem.module.css';
import { DropdownOption } from 'types/types';
import { cn } from 'utils/cn';
import ClientOnlyPortal from 'components/common/ClientOnlyPortal';
import { CaretUpDown } from '@phosphor-icons/react';

export interface BCustomSelectProps<T extends DropdownOption<number | string>> {
  options?: T[];
  selectedValue?: T | null;
  selectedValueRender?: ReactNode;
  wrapperClass?: string;
  dropdownClass?: string;
  children?: ReactNode[];
  optionClass?: string;
  onChange?: (val: T) => void;
  selector?: string;
  renderedSelectedValue?: () => ReactNode;
  parentSelector?: string;
  show: boolean;
  setShow: (val: boolean) => void;
  disabled?: boolean;
}

function BCustomSelect<T extends DropdownOption<number | string>>({
  options,
  selectedValue,
  onChange,
  wrapperClass,
  dropdownClass,
  children,
  optionClass,
  selector,
  renderedSelectedValue,
  parentSelector,
  show,
  setShow,
  disabled,
}: BCustomSelectProps<T>) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const openDropdown = () => {
    !disabled && setShow(true);
  };
  const closeDropdown = () => setShow(false);

  const portal = (children: ReactNode) =>
    selector ? (
      <ClientOnlyPortal selector={selector}>{children}</ClientOnlyPortal>
    ) : (
      <>{children}</>
    );

  const parentRect =
    typeof window !== 'undefined'
      ? document.querySelector(parentSelector || '')?.getBoundingClientRect()
      : undefined;

  const rect = {
    top:
      -(parentRect?.top || 0) +
      (wrapperRef.current?.getBoundingClientRect().top || 0) +
      (wrapperRef.current?.getBoundingClientRect().height || 0) +
      2 +
      'px',
    left:
      -(parentRect?.left || 0) +
      (wrapperRef.current?.getBoundingClientRect().left || 0) +
      2 +
      'px',
    width: `${wrapperRef.current?.getBoundingClientRect().width || 0}px`,
  };

  return (
    <div className={cn(wrapperClass)} ref={wrapperRef}>
      <div
        className={cn(
          formItemStyles['b-form-item'],
          'flex w-full items-center justify-between',
          {
            'cursor-not-allowed border-slate-500 bg-slate-50': disabled,
          }
        )}
        onClick={openDropdown}
        role="button"
        tabIndex={0}
      >
        {renderedSelectedValue ? renderedSelectedValue() : selectedValue?.label}
        <CaretUpDown className="ml-auto" size={18} />
      </div>
      {portal(
        <Dropdown
          show={show}
          onClose={closeDropdown}
          wrapperClass={cn(`absolute transform z-400`, dropdownClass)}
          dropdownStyle={{
            top: rect.top,
            left: rect.left,
            width: rect.width,
          }}
        >
          <div className="h-60 overflow-auto">
            {children
              ? children
              : options?.map((option) => (
                  <DropdownClickableItem
                    key={option.value}
                    isActive={!!option.value}
                    onClick={() => onChange?.(option)}
                    wrapperClass={optionClass}
                  >
                    {option.label}
                  </DropdownClickableItem>
                ))}
          </div>
        </Dropdown>
      )}
    </div>
  );
}

export default BCustomSelect;
