import useTranslation from 'next-translate/useTranslation';
import { ChangeEvent, FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import BInput, { BInputProps } from './BInput';

interface BNumberInputProps extends BInputProps {
  handleChange: (value: string) => void;
  boundaryErrorKey?: string;
}

const BNumberInput: FunctionComponent<BNumberInputProps> = ({
  handleChange,
  min,
  max,
  boundaryErrorKey,
  ...props
}) => {
  const { t } = useTranslation();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
      .replace(/[^0-9]/g, '')
      .replace(/(\..*)\./g, '$1');
    const isValuesLessThanMin = min && +value < Number(min);
    const isValuesGreaterThanMax = max && +value > Number(max);
    if (value !== '' && (isValuesLessThanMin || isValuesGreaterThanMax)) {
      if (boundaryErrorKey) {
        toast.error(
          t(boundaryErrorKey, {
            min,
            max,
          }),
          { toastId: 'boundaryErrorToast' }
        );
      }
      return false;
    }
    handleChange(value);
  };

  return (
    <BInput {...props} type="text" inputMode="numeric" onChange={onChange} />
  );
};

export default BNumberInput;
