import { useRouter } from 'next/router';
import qs from 'qs';

interface AddQueryParamOptions {
  shallow: boolean;
}

export function useQueryParams() {
  const router = useRouter();

  const addQueryParam = (
    queryParams: Record<string, unknown>,
    options: AddQueryParamOptions = { shallow: true }
  ) => {
    const queryParamsJoined = qs.stringify(queryParams);

    router.replace(
      {
        pathname: router.pathname,
        search: `?${queryParamsJoined}`,
      },
      `${removeQueryParamsFromAsPath()}?${queryParamsJoined}`,
      options
    );
  };

  const removeQueryParamsFromAsPath = () => {
    const { asPath } = router;
    return asPath?.split('?')?.filter((f) => f)[0];
  };

  const getQueryParam = (key: string): string | string[] | undefined => {
    const queryParamSplitted = router.asPath?.split('?').filter((f) => f);
    const queryParams = queryParamSplitted[queryParamSplitted.length - 1];
    const parsedQp = qs.parse(queryParams);
    return parsedQp[key] as string;
  };

  const removeQueryParams = () => {
    const { pathname } = router;
    const strippedAsPath = removeQueryParamsFromAsPath();
    router.replace({ pathname }, strippedAsPath, {
      shallow: true,
    });
  };

  return {
    addQueryParam,
    getQueryParam,
    removeQueryParams,
  };
}
