import { useRef } from 'react';

export function useFirstRender(cb: () => void) {
  const isFirstRender = useRef<boolean>(false);

  if (!isFirstRender.current) {
    cb();
    isFirstRender.current = true;
  }
}
