import BButton from 'components/common/BButton';
import BCheckbox from 'components/Form/BCheckbox';
import BLabeledInput from 'components/Form/BLabeledInput';
import BLabelWrapper from 'components/Form/BLabelWrapper';
import BPhoneInput from 'components/Form/BPhoneInput';
import { AuthModalMode } from 'enums/authModalMode';
import { Locale } from 'enums/locale';
import { useForm } from 'hooks/useForm';
import { useRouter } from 'next-translate-routes';
import useTranslation from 'next-translate/useTranslation';
import { FormEvent, FunctionComponent, useRef } from 'react';
import { CountryData } from 'react-phone-input-2';
import { toast } from 'react-toastify';
import { analyticsService } from 'services/AnalyticsService';
import { authService } from 'services/AuthService';
import { useUserState } from 'states/useUserState';
import {
  AuthModalIndividualProps,
  OnModeChangeParams,
  RegisterState,
} from 'types/auth.type';
import {
  emailValidation,
  nameValidation,
  passwordValidation,
  phoneNumberValidation,
  surnameValidation,
  validateSchema,
} from 'validation/auth.validation';
import AuthModalLabelItem from './AuthModalLabelItem';
import BPasswordInput from 'components/Form/BPasswordInput';
import SocialLoginSection from './SocialLoginSection';

const Register: FunctionComponent<AuthModalIndividualProps> = ({
  onModeChange,
  email,
  password,
  phoneNumber,
}) => {
  const { t } = useTranslation();
  const { setIsUserLoggedIn } = useUserState();
  const { locale } = useRouter();
  const kvkkRef = useRef<HTMLDivElement>(null);
  const termsRef = useRef<HTMLDivElement>(null);

  const defaultState: RegisterState = {
    name: '',
    email: email || '',
    phoneNumber: phoneNumber || '',
    password: password || '',
    country: '',
    hasGivenETKConsents: false,
    _surname: '',
    _kvkk: false,
    _terms: false,
  };

  const validateState = (): boolean => {
    const errors: string[] = [];
    if (!state._kvkk) {
      toast.error(t('navbar:agreements.kvkkAndConsent.error'));
      errors.push('_kvkk');
      kvkkRef.current?.classList.add('text-red-500');
    }

    if (!state._terms) {
      toast.error(t('navbar:agreements.terms.error'));
      errors.push('_terms');
      termsRef.current?.classList.add('text-red-500');
    }

    if (errors.length === 0) {
      kvkkRef.current?.classList.remove('text-red-500');
      termsRef.current?.classList.remove('text-red-500');
      return true;
    }
    return false;
  };

  const [state, dispatch] = useForm(defaultState);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateState()) {
      return;
    }

    const schema = {
      name: nameValidation,
      _surname: surnameValidation,
      email: emailValidation,
      password: passwordValidation,
      phoneNumber: phoneNumberValidation,
    };

    validateSchema(schema, state, t, async () => {
      const { phoneNumber, _surname, name, ...payload } = state;
      await authService.register({
        ...payload,
        name: name.trim() + ' ' + _surname.trim(),
        phoneNumber: `+${phoneNumber}`,
      });
      analyticsService.signUp('LOCAL');
      setIsUserLoggedIn();
    });
  };

  const onModeChangeWrapped = (mode: OnModeChangeParams['mode']) => {
    onModeChange({
      email: state.email,
      password: state.password,
      phoneNumber: state.phoneNumber,
      mode,
    });
  };

  const redirectToKvkk = () => {
    const link =
      locale === Locale.TR
        ? '/legal/kvkk-aydinlatma/'
        : '/en/legal/kvkk-clarification/';
    window.open(link, '_blank');
  };

  const redirectToTerms = () => {
    const link =
      locale === Locale.TR
        ? '/legal/kullanim-sartlari/'
        : '/en/legal/terms-of-use/';
    window.open(link, '_blank');
  };

  const redirectToOpenConsent = () => {
    const link =
      locale === Locale.TR ? '/legal/acik-riza/' : '/en/legal/open-consent/';
    window.open(link, '_blank');
  };

  const redirectToETK = () => {
    const link = locale === Locale.TR ? '/legal/etk/' : '/en/legal/etk/';
    window.open(link, '_blank');
  };

  return (
    <div>
      <form
        autoComplete="off"
        onSubmit={onSubmit}
        className="space-y-4"
        noValidate
      >
        <div className="flex w-full flex-col gap-4 md:flex-row">
          <BLabeledInput
            type="text"
            wrapperClassName="flex-1"
            value={state.name}
            onChange={(e) =>
              dispatch({ type: 'name', payload: e.target.value })
            }
            label={t('navbar:name')}
          />
          <BLabeledInput
            type="text"
            wrapperClassName="flex-1"
            value={state._surname}
            onChange={(e) =>
              dispatch({ type: '_surname', payload: e.target.value })
            }
            label={t('navbar:surname')}
          />
        </div>
        <BLabelWrapper label={t('navbar:phoneNumber')}>
          <BPhoneInput
            value={state.phoneNumber}
            onChange={(value, country) => {
              dispatch({ type: 'phoneNumber', payload: `${value}` });
              dispatch({
                type: 'country',
                payload: `${(country as CountryData).name}`,
              });
            }}
          />
        </BLabelWrapper>
        <BLabeledInput
          type="email"
          value={state.email}
          onChange={(e) => dispatch({ type: 'email', payload: e.target.value })}
          label={t('navbar:email')}
        />
        <BPasswordInput
          value={state.password}
          onChange={(e) =>
            dispatch({ type: 'password', payload: e.target.value })
          }
          label={t('navbar:password')}
        />
        <BCheckbox
          checked={state._kvkk}
          onClick={() =>
            dispatch({
              type: '_kvkk',
              payload: !state._kvkk,
            })
          }
          label={
            <div className="mx-auto max-w-md" ref={kvkkRef}>
              <span>{t('navbar:agreements.kvkkAndConsent.start')} </span>
              <span
                className="underline hover:no-underline"
                onClick={redirectToKvkk}
                role="button"
                tabIndex={0}
              >
                {t('navbar:agreements.kvkkAndConsent.kvkk')}
              </span>{' '}
              <span>{t('navbar:agreements.kvkkAndConsent.middle')} </span>
              <span
                className="underline hover:no-underline"
                onClick={redirectToOpenConsent}
                role="button"
                tabIndex={0}
              >
                {t('navbar:agreements.kvkkAndConsent.openConsent')}
              </span>{' '}
              <span>{t('navbar:agreements.kvkkAndConsent.end')}</span>
            </div>
          }
        />
        <BCheckbox
          checked={state.hasGivenETKConsents}
          onClick={() =>
            dispatch({
              type: 'hasGivenETKConsents',
              payload: !state.hasGivenETKConsents,
            })
          }
          label={
            <div className="mx-auto max-w-md">
              <span>{t('navbar:agreements.etk.start')} </span>
              <span
                className="underline hover:no-underline"
                onClick={redirectToETK}
                role="button"
                tabIndex={0}
              >
                {t('navbar:agreements.etk.link')}
              </span>{' '}
              <span>{t('navbar:agreements.etk.end')}</span>
            </div>
          }
        />
        <BCheckbox
          checked={state._terms}
          onClick={() =>
            dispatch({
              type: '_terms',
              payload: !state._terms,
            })
          }
          label={
            <div className="mx-auto max-w-md" ref={termsRef}>
              <span>{t('navbar:agreements.terms.start')} </span>
              <span
                className="underline hover:no-underline"
                onClick={redirectToTerms}
                role="button"
                tabIndex={0}
              >
                {t('navbar:agreements.terms.link')}
              </span>{' '}
              <span>{t('navbar:agreements.terms.end')}</span>
            </div>
          }
        />
        <BButton fluid type="submit">
          {t('navbar:signUpAction')}
        </BButton>
      </form>
      <SocialLoginSection
        onModeChange={onModeChange}
        phoneNumber={phoneNumber}
      />
      <AuthModalLabelItem
        actionKey="navbar:signInLabel"
        className="mt-6"
        onClick={() => onModeChangeWrapped(AuthModalMode.LOGIN)}
        labelKey="navbar:signInAction"
      />
      <AuthModalLabelItem
        actionKey="navbar:forgotPasswordAction"
        className="mt-1"
        onClick={() => onModeChangeWrapped(AuthModalMode.FORGOT_PASSWORD)}
        labelKey="navbar:forgotPasswordLabel"
      />
    </div>
  );
};

export default Register;
