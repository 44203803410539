import axios from 'axios';
import {
  setupCache,
  buildMemoryStorage,
  CacheOptions,
} from 'axios-cache-interceptor';
import { HOUR_IN_SECONDS } from 'enums/durations';

const baseURL =
  process.env.NEXT_PUBLIC_APP_ENV === 'prod'
    ? 'https://api.teknevia.com/api'
    : 'https://api.stage.teknevia.com/api';
// : 'http://localhost:8080/api';

const cacheOptions: CacheOptions = {
  headerInterpreter: () => HOUR_IN_SECONDS * 1000,
  cacheTakeover: false,
  storage: buildMemoryStorage(
    /* cloneData default=*/ false,
    /* cleanupInterval default=*/ HOUR_IN_SECONDS * 1000,
    /* maxEntries default=*/ false
  ),
};

const client = axios.create({
  baseURL,
});

const token = `tkv-admin:OjBJ2OYLLmkp9W6QgfogacGQ`;
const encodedToken = Buffer.from(token).toString('base64');

const seoClient = setupCache(
  axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_BLOG_BASEPATH}`,
    headers: {
      Authorization: `Basic ${encodedToken}`,
    },
  }),
  cacheOptions
);

const cmsClient = setupCache(
  axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_CMS_BASEPATH}`,
  })
);

const messagingApiClient = axios.create({
  baseURL:
    process.env.NEXT_PUBLIC_APP_ENV === 'prod'
      ? 'https://messaging.teknevia.com/'
      : 'https://messaging.stage.teknevia.com/',
});

const discordAlertServiceClient = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_DISCORD_ALERT_SERVICE_BASEPATH}`,
});

export {
  client,
  seoClient,
  cmsClient,
  discordAlertServiceClient,
  messagingApiClient,
};
