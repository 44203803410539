import classNames from 'classnames';
import TranslatedLink from 'components/common/TranslatedLink';
import { FunctionComponent, ReactNode } from 'react';
import { BottomNavigationMenuItem } from 'types/layout.type';

interface BottomMenuItemWrapperProps {
  menuItem: BottomNavigationMenuItem;
  children: ReactNode;
  wrapperClass?: string;
  itemClass?: string;
}

const BottomMenuItemWrapper: FunctionComponent<BottomMenuItemWrapperProps> = ({
  menuItem,
  children,
  wrapperClass,
  itemClass,
}) => {
  const itemSpacingClasses = 'inline-block p-1';
  return (
    <li
      className={classNames(
        wrapperClass,
        'flex flex-1 list-none justify-center'
      )}
    >
      {menuItem.href ? (
        menuItem.isLinkOutside ? (
          <a
            href={menuItem.href}
            rel="nofollow noreferrer noopener"
            title={menuItem.label}
            target="_blank"
            className={classNames(itemSpacingClasses, itemClass)}
          >
            {children}
          </a>
        ) : (
          <TranslatedLink href={menuItem.href}>
            <a
              title={menuItem.label}
              className={classNames(itemSpacingClasses, itemClass)}
            >
              {children}
            </a>
          </TranslatedLink>
        )
      ) : (
        <div
          className={classNames(itemSpacingClasses, itemClass)}
          onClick={menuItem.onClick}
          role="button"
          tabIndex={0}
        >
          {children}
        </div>
      )}
    </li>
  );
};

export default BottomMenuItemWrapper;
