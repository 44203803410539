import CdnSvg from 'components/common/CdnSvg';
import { FunctionComponent } from 'react';

interface SocialLoginButtonProps {
  iconLink: string;
  label: string;
  onClick: () => void;
}

const SocialLoginButton: FunctionComponent<SocialLoginButtonProps> = ({
  iconLink,
  label,
  onClick,
}) => {
  return (
    <button
      className="relative flex cursor-pointer justify-center rounded-lg border border-primary-500 px-6 py-3 font-medium"
      onClick={onClick}
    >
      <CdnSvg src={iconLink} size={20} className="absolute left-6 top-3.5" />
      {label}
    </button>
  );
};

export default SocialLoginButton;
