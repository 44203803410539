import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface ModalStore {
  currentOpenModals: string[];
  updateCurrentOpenModals: (val: 'open' | 'close', modalId: string) => void;
  removeStyles: (inset: number) => void;
  addStyles: (inset: number) => void;
}

export const useModalState = create<ModalStore>()(
  devtools((set) => {
    return {
      currentOpenModals: [],
      addStyles(inset) {
        const insetStyle = `-${inset}px 0 0`;
        document.documentElement.style.minHeight = '100vh';
        document.body.style.position = 'fixed';
        document.body.style.overflow = 'hidden';
        document.body.style.inset = insetStyle;
        document.body.style.overscrollBehaviorX = 'none';
        document.documentElement.style.overscrollBehaviorX = 'none';
      },
      removeStyles(inset) {
        document.documentElement.style.minHeight = '';
        document.body.style.position = '';
        document.body.style.overflow = '';
        document.body.style.inset = '';
        document.documentElement.style.minHeight = '';
        document.documentElement.style.overscrollBehaviorX = '';
        if (inset) {
          window.scrollTo({
            top: inset,
          });
        }
      },
      updateCurrentOpenModals: (val, modalId) =>
        set((state) => {
          const currentOpenModals = [...state.currentOpenModals];
          if (val === 'open') {
            currentOpenModals.push(modalId);
            if (currentOpenModals.length === 1) {
              state.addStyles(window.scrollY);
            }
          } else {
            const modalIdIdx = currentOpenModals.findIndex(
              (val) => val === modalId
            );
            modalIdIdx !== -1 && currentOpenModals.splice(modalIdIdx, 1);
            if (currentOpenModals.length === 0) {
              const inset = document?.body?.style?.inset?.match(/\d+/g) ?? [0];
              state.removeStyles(+inset![0]);
            }
          }
          return {
            ...state,
            currentOpenModals,
          };
        }),
    };
  })
);
