import { useRouter } from 'next/router';
import { useEffect } from 'react';

export function usePrevLink() {
  const { asPath } = useRouter();
  const currentLinkKey = 'link_current';
  const previousLinkKey = 'link_prev';
  const lastUsedReferrerKey = 'last_used_referrer';

  useEffect(() => {
    const currentVal = sessionStorage.getItem(currentLinkKey) ?? '';
    if (currentVal !== asPath) {
      sessionStorage.setItem(currentLinkKey, asPath);
      sessionStorage.setItem(previousLinkKey, currentVal);
    }
  }, [asPath]);

  const getPrevLink = () => {
    const lastUsedReferrer = sessionStorage.getItem(lastUsedReferrerKey);
    const referrer =
      lastUsedReferrer !== document.referrer ? document.referrer : '';
    return sessionStorage.getItem(previousLinkKey) || referrer;
  };

  return {
    getPrevLink,
  };
}
