import { TouchEventHandler, useRef } from 'react';

export const useSwipe: UseSwipe = (callbacks = {}, options = {}) => {
  const { left, right, down, up } = callbacks;
  const {
    swipeSpeed = 1,
    threshold = 150,
    selector,
    swipeBoundary = {},
  } = options;
  const defaultCoordinations: TouchCoordinations = {
    touchStart: { x: 0, y: 0, time: Date.now() },
  };
  const touchCoordsRef = useRef<TouchCoordinations>({
    ...defaultCoordinations,
  });
  const fnsRef = useRef<Callbacks>({ up, down, left, right });

  fnsRef.current = {
    up,
    left,
    down,
    right,
  };

  const handleTouchStart: TouchEventHandler = (e) => {
    if (
      left &&
      swipeBoundary.left &&
      e.targetTouches[0].clientX > swipeBoundary.left
    ) {
      touchCoordsRef.current = {
        touchStart: { x: -1, y: -1, time: Date.now() },
      };
      return;
    }
    if (
      right &&
      swipeBoundary.right &&
      e.targetTouches[0].clientX <
        document.documentElement.clientWidth - swipeBoundary.right
    ) {
      touchCoordsRef.current = {
        touchStart: { x: -1, y: -1, time: Date.now() },
      };
      return;
    }
    if (
      up &&
      swipeBoundary.up &&
      e.targetTouches[0].clientY > swipeBoundary.up
    ) {
      touchCoordsRef.current = {
        touchStart: { x: -1, y: -1, time: Date.now() },
      };
      return;
    }
    if (
      down &&
      swipeBoundary.down &&
      e.targetTouches[0].clientY <
        document.documentElement.clientHeight - swipeBoundary.down
    ) {
      touchCoordsRef.current = {
        touchStart: { x: -1, y: -1, time: Date.now() },
      };
      return;
    }
    touchCoordsRef.current.touchStart.x = e.targetTouches[0].clientX;
    touchCoordsRef.current.touchStart.y = e.targetTouches[0].clientY;
    touchCoordsRef.current.touchStart.time = Date.now();
  };

  const handleTouchMove: TouchEventHandler = (e) => {
    if (
      touchCoordsRef.current.touchStart.x === -1 &&
      touchCoordsRef.current.touchStart.y === -1
    ) {
      return;
    }
    const distX =
      e.changedTouches[0].clientX - touchCoordsRef.current.touchStart.x;
    const distY =
      e.changedTouches[0].clientY - touchCoordsRef.current.touchStart.y;
    if (selector) {
      const modal = document.querySelector(selector) as HTMLDivElement;
      if (modal) {
        if ((right && distX < 0) || (left && distX > 0)) {
          modal.style.transform = `translate(${distX}px, 0px)`;
        } else if ((down && distY > 0) || (up && distY < 0)) {
          modal.style.transform = `translate(0px, ${distY}px)`;
        }
      }
    }
  };

  const handleTouchEnd: TouchEventHandler = (e) => {
    if (
      touchCoordsRef.current.touchStart.x === -1 &&
      touchCoordsRef.current.touchStart.y === -1
    ) {
      return;
    }
    if (selector) {
      const modal = document.querySelector(selector) as HTMLDivElement;
      if (modal) {
        modal.style.transform = `translate(0px, 0px)`;
      }
    }

    const touchEndX = e.changedTouches[0].clientX;
    const touchEndY = e.changedTouches[0].clientY;
    const touchStartX = touchCoordsRef.current.touchStart.x;
    const touchStartY = touchCoordsRef.current.touchStart.y;
    const elapsedTime =
      (Date.now() - touchCoordsRef.current.touchStart.time) / 1000;
    if (elapsedTime > swipeSpeed) {
      return;
    }
    const xDistance = touchStartX - touchEndX;
    const yDistance = touchStartY - touchEndY;

    if (Math.abs(xDistance) < threshold && Math.abs(yDistance) < threshold) {
      return;
    }

    if (Math.abs(xDistance) >= Math.abs(yDistance)) {
      xDistance > 0 ? fnsRef.current.right?.() : fnsRef.current.left?.();
    } else {
      yDistance > 0 ? fnsRef.current.down?.() : fnsRef.current.up?.();
    }
  };

  return {
    handleTouchStart,
    handleTouchEnd,
    handleTouchMove,
  };
};

interface Callbacks {
  left?: () => void;
  right?: () => void;
  up?: () => void;
  down?: () => void;
}

interface SwipeBoundary {
  left?: number;
  right?: number;
  up?: number;
  down?: number;
}

interface Options {
  threshold?: number;
  swipeSpeed?: number; // in seconds
  selector?: string;
  swipeBoundary?: SwipeBoundary;
}

interface ReturnType {
  handleTouchStart: TouchEventHandler<HTMLElement>;
  handleTouchEnd: TouchEventHandler<HTMLElement>;
  handleTouchMove: TouchEventHandler<HTMLElement>;
}

type UseSwipe = (callbacks: Callbacks, options?: Options) => ReturnType;

interface Coordination {
  x: number;
  y: number;
  time: number;
}

interface TouchCoordinations {
  touchStart: Coordination;
}
