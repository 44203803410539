import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export default function useHeroIntersection(routesWithHero: string[]) {
  const { pathname } = useRouter();
  const [intersecting, setIntersecting] = useState<boolean>(
    routesWithHero.includes(pathname)
  );

  useEffect(() => {
    const target = document.getElementById('hero');

    if (target) {
      const navbar = document.querySelector('header.navbar') as HTMLElement;
      // const navbarLogo = navbar.querySelector('.navbar-logo') as HTMLElement;
      // const navbarSearch = navbar.querySelector('.navbar-search') as HTMLElement;

      const options = {
        rootMargin: `-${navbar!.offsetHeight}px`,
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (navbar) {
            if (entry.isIntersecting) {
              setIntersecting(true);
              // navbar.classList.add('-transparent');
              // navbar.classList.remove('sticky');
              // navbar.classList.remove('invisible');
              // navbarLogo.classList.remove('hidden');
              // navbarLogo.classList.remove('hidden');
              // navbarSearch?.classList.add('hidden');
            } else {
              setIntersecting(false);
              // navbar.classList.remove('-transparent');
              // if (pathname === '/partner') {
              //   navbar.classList.add('invisible');
              // } else {
              //   navbar.classList.add('sticky');
              // }
              // navbarLogo.classList.add('hidden');
              // navbarSearch?.classList.remove('hidden');
            }
          }
        });
      }, options);

      observer.observe(target!);
      return () => {
        // navbar.classList.remove('-transparent');
        // navbarSearch?.classList.remove('hidden');
        // navbar.classList.add('sticky');
        // navbar.classList.remove('invisible');
        observer.disconnect();
      };
    } else {
      setIntersecting(routesWithHero.includes(pathname));
    }
  }, [pathname]);

  return {
    intersecting,
  };
}
