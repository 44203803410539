import BNotifBadge from 'components/common/BNotfiBadge';
import Dropdown from 'components/common/Dropdown';
import DropdownClickableItem from 'components/common/DropdownClickableItem';
import TranslatedLink from 'components/common/TranslatedLink';
import { AuthModalMode } from 'enums/authModalMode';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import { authService } from 'services/AuthService';
import { useAuthModalState } from 'states/useAuthModalState';
import { useNotificationState } from 'states/useNotificationState';
import { useRemoteConfigState } from 'states/useRemoteConfigState';
import { useUserState } from 'states/useUserState';
import { DropdownItem, DropdownProps } from 'types/dropdown.type';

const UserDropdown: FunctionComponent<DropdownProps> = ({ show, onClose }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { isUserLoggedIn, setIsUserLoggedIn } = useUserState();
  const { getConfig } = useRemoteConfigState();
  const isMessagingActive = getConfig('MESSAGING_ENABLED', true);

  const { setShowAuthModal, setAuthMode } = useAuthModalState();
  const { getOffersPageBadges, getReservationsPageBadges } =
    useNotificationState();

  const closedClick = (cb?: () => void) => {
    return () => {
      cb?.();
      onClose?.();
    };
  };

  const openAuthModal = (mode: AuthModalMode) => {
    setAuthMode(mode);
    setShowAuthModal(true);
  };

  const loggedOutMenuItems: DropdownItem[] = [
    {
      label: 'navbar:login',
      action: closedClick(() => openAuthModal(AuthModalMode.LOGIN)),
    },
    {
      label: 'navbar:register',
      action: closedClick(() => openAuthModal(AuthModalMode.REGISTER)),
    },
    {
      label: 'navbar:becomePartner',
      action: closedClick(() => router.push('/partner')),
    },
    {
      label: 'navbar:campaigns',
      action: closedClick(),
      href: '/kampanyalar',
    },
    {
      label: 'navbar:helpCenter',
      action: closedClick(),
      href: '/yardim-merkezi',
    },
  ];

  const loggedInMenuItems: DropdownItem[] = [
    {
      label: 'navbar:offers',
      action: closedClick(),
      href: '/tekliflerim',
      badgeCount: getOffersPageBadges(),
    },
    {
      label: 'navbar:reservations',
      action: closedClick(),
      href: '/rezervasyonlarim',
      badgeCount: getReservationsPageBadges(),
    },
    ...(isMessagingActive
      ? [
          {
            label: 'navbar:messages',
            action: closedClick(),
            href: '/message',
          },
        ]
      : []),
    {
      label: 'navbar:favorites',
      action: closedClick(),
      href: '/favorilerim',
    },
    {
      label: 'navbar:profile',
      action: closedClick(),
      href: '/kisisel-bilgilerim',
    },
    {
      label: 'navbar:securityAndPrivacy',
      action: closedClick(),
      href: '/gizlilik',
    },
    {
      label: 'navbar:logout',
      action: closedClick(() => {
        setShowAuthModal(false);
        authService.logout();
        setIsUserLoggedIn();
        toast.success(t('common:auth.logout.success'), { toastId: 'logout' });
      }),
    },
    {
      label: 'navbar:campaigns',
      action: closedClick(),
      href: '/kampanyalar',
    },
    {
      label: 'navbar:helpCenter',
      action: closedClick(),
      href: '/yardim-merkezi',
    },
  ];

  const dropdownItems = isUserLoggedIn ? loggedInMenuItems : loggedOutMenuItems;

  return (
    <Dropdown show={show} onClose={onClose}>
      <nav>
        {dropdownItems.map((item) =>
          item.href ? (
            <TranslatedLink href={item.href} key={item.label}>
              <a>
                <DropdownClickableItem
                  onClick={item.action}
                  wrapperClass="flex items-center justify-between"
                >
                  {t(item.label)}
                  {item.badgeCount ? (
                    <BNotifBadge className="ml-2">
                      {item.badgeCount}
                    </BNotifBadge>
                  ) : null}
                </DropdownClickableItem>
              </a>
            </TranslatedLink>
          ) : (
            <DropdownClickableItem key={item.label} onClick={item.action}>
              {t(item.label)}
            </DropdownClickableItem>
          )
        )}
      </nav>
    </Dropdown>
  );
};

export default UserDropdown;
