import { deleteCookie, getCookie, getCookies, setCookie } from 'cookies-next';
import { OptionsType } from 'cookies-next/lib/types';

const domain = {
  prod: 'teknevia.com',
  stage: 'stage.teknevia.com',
};

class CookieService {
  private _domain =
    domain[process.env.NEXT_PUBLIC_APP_ENV as keyof typeof domain] ||
    'localhost';

  getCookies(options: OptionsType) {
    return getCookies({
      domain: this._domain,
      ...options,
    });
  }
  setCookie(key: string, data: unknown, options: OptionsType) {
    setCookie(key, data, {
      domain: this._domain,
      ...options,
    });
  }
  deleteCookie(key: string, options: OptionsType) {
    deleteCookie(key, {
      domain: this._domain,
      ...options,
    });
  }
  getCookie(key: string, options: OptionsType) {
    return getCookie(key, {
      domain: this._domain,
      ...options,
    });
  }
}

export const cookieService = new CookieService();
