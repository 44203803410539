import { FunctionComponent } from 'react';

interface CdnSvgProps {
  src: string;
  size?: number;
  className?: string;
  prioritized?: boolean;
  alt?: string;
  width?: number;
  height?: number;
}

const CdnSvg: FunctionComponent<CdnSvgProps> = ({
  src,
  size,
  className,
  prioritized = false,
  alt = '',
  width,
  height,
}) => {
  return (
    <img
      alt={alt}
      loading={prioritized ? 'eager' : 'lazy'}
      className={className}
      width={width ? width : size ? size : undefined}
      height={height ? height : size ? size : undefined}
      src={`https://cdn.teknevia.com/storefront/icons/${src}`}
    />
  );
};

export default CdnSvg;
