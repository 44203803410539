import BButton from 'components/common/BButton';
import BLabeledInput from 'components/Form/BLabeledInput';
import BLabelWrapper from 'components/Form/BLabelWrapper';
import BPhoneInput from 'components/Form/BPhoneInput';
import { AuthModalMode } from 'enums/authModalMode';
import { useForm } from 'hooks/useForm';
import useTranslation from 'next-translate/useTranslation';
import { FormEvent, FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import { authService } from 'services/AuthService';
import {
  AuthModalIndividualProps,
  ForgotPasswordState,
  OnModeChangeParams,
  PasswordResetCodeState,
} from 'types/auth.type';
import {
  emailValidation,
  passwordValidation,
  phoneNumberValidation,
  validateSchema,
} from 'validation/auth.validation';
import AuthModalLabelItem from './AuthModalLabelItem';
import BPasswordInput from 'components/Form/BPasswordInput';

const ForgotPassword: FunctionComponent<AuthModalIndividualProps> = ({
  onModeChange,
  email,
  password,
  phoneNumber,
}) => {
  const { t } = useTranslation();

  const defaultState: ForgotPasswordState = {
    email: email || '',
    phoneNumber: phoneNumber || '',
    passwordResetCode: '',
    newPassword: password || '',
    passwordResetCodeState: PasswordResetCodeState.HIDDEN,
  };

  const [state, dispatch] = useForm(defaultState);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const schema = {
      email: emailValidation,
      newPassword: passwordValidation,
      phoneNumber: phoneNumberValidation,
    };

    validateSchema(schema, state, t, async () => {
      const {
        passwordResetCodeState,
        passwordResetCode,
        phoneNumber,
        ...payload
      } = state;

      const message = await authService.forgotPassword({
        ...payload,
        passwordResetCode: passwordResetCode || undefined,
        phoneNumber: `+${phoneNumber}`,
      });
      toast.success(message);
      if (passwordResetCodeState === PasswordResetCodeState.SHOWN) {
        onModeChangeWrapped(AuthModalMode.LOGIN);
        return;
      }
      dispatch({
        type: 'passwordResetCodeState',
        payload: PasswordResetCodeState.SHOWN,
      });
    });
  };

  const onModeChangeWrapped = (mode: OnModeChangeParams['mode']) => {
    onModeChange({
      email: state.email,
      password: state.newPassword,
      mode,
    });
  };

  return (
    <div>
      <form onSubmit={onSubmit} className="space-y-6" noValidate>
        <BLabeledInput
          type="email"
          value={state.email}
          onChange={(e) => dispatch({ type: 'email', payload: e.target.value })}
          label={t('navbar:email')}
        />
        <BLabelWrapper label={t('navbar:phoneNumber')}>
          <BPhoneInput
            value={state.phoneNumber}
            onChange={(value) =>
              dispatch({ type: 'phoneNumber', payload: `${value}` })
            }
          />
        </BLabelWrapper>
        <BPasswordInput
          value={state.newPassword}
          onChange={(e) =>
            dispatch({ type: 'newPassword', payload: e.target.value })
          }
          label={t('navbar:newPassword')}
        />
        {state.passwordResetCodeState ===
        PasswordResetCodeState.HIDDEN ? null : (
          <BLabeledInput
            type="text"
            value={state.passwordResetCode}
            onChange={(e) =>
              dispatch({
                type: 'passwordResetCode',
                payload: e.target.value,
              })
            }
            label={t('navbar:passwordResetCode')}
          />
        )}
        <BButton fluid type="submit">
          {t('navbar:forgotPasswordAction')}
        </BButton>
      </form>
      <AuthModalLabelItem
        labelKey="navbar:signInLabel"
        className="mt-6"
        actionKey="navbar:signInAction"
        onClick={() => onModeChangeWrapped(AuthModalMode.LOGIN)}
      />
      <AuthModalLabelItem
        actionKey="navbar:signUpLabel"
        className="mt-1"
        onClick={() => onModeChangeWrapped(AuthModalMode.REGISTER)}
        labelKey="navbar:signUpAction"
      />
    </div>
  );
};

export default ForgotPassword;
