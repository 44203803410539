import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface RemoteConfigStore {
  configs: Record<string, unknown>;
  setConfigs: (configs: RemoteConfigStore['configs']) => void;
  getConfig: (key: string, defaultVal: unknown) => unknown;
}

export const useRemoteConfigState = create<RemoteConfigStore>()(
  devtools((set, get) => {
    return {
      configs: {},
      setConfigs: (configs) =>
        set((state) => {
          return {
            ...state,
            configs: configs,
          };
        }),
      getConfig(key, defaultVal) {
        const val = get().configs[key];
        return val !== undefined ? val : defaultVal;
      },
    };
  })
);
