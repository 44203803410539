import classNames from 'classnames';
import { IconProps, MinusCircle, PlusCircle } from '@phosphor-icons/react';
import {
  ButtonHTMLAttributes,
  FunctionComponent,
  InputHTMLAttributes,
} from 'react';
import BNumberInput from './BNumberInput';
import { cn } from 'utils/cn';

interface BStepperInputProps {
  minLimit: number;
  maxLimit: number;
  value: number | string;
  onChange: (val: number | string) => void;
  inputEnabled?: boolean;
  buttonClasses?: string;
  wrapperClasses?: string;
  inputClasses?: string;
  suffix?: string;
  iconSize?: number;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  boundaryErrorKey?: string;
}

const BStepperInput: FunctionComponent<BStepperInputProps> = ({
  minLimit,
  maxLimit,
  value,
  onChange,
  inputEnabled = false,
  wrapperClasses,
  inputProps = {},
  buttonProps = {},
  suffix,
  iconSize = 28,
  boundaryErrorKey,
}) => {
  const StepButton = (props: {
    onClick: () => void;
    disabled: boolean;
    icon: React.ForwardRefExoticComponent<
      IconProps & React.RefAttributes<SVGSVGElement>
    >;
  }) => (
    <button
      type="button"
      disabled={props.disabled}
      onClick={props.onClick}
      {...buttonProps}
      className={classNames(
        'disabled:cursor-not-allowed disabled:text-slate-400',
        buttonProps.className
      )}
    >
      <props.icon size={iconSize} />
    </button>
  );
  return (
    <div
      className={cn(
        'relative flex items-center justify-center gap-2',
        wrapperClasses
      )}
    >
      <StepButton
        onClick={() => onChange(+value - 1)}
        disabled={+value <= minLimit}
        icon={MinusCircle}
      />
      <div className="flex items-center">
        <BNumberInput
          onClick={(e) => (e.target as HTMLInputElement).select()}
          readOnly={!inputEnabled}
          min={minLimit}
          max={maxLimit}
          value={value}
          handleChange={(value) => onChange(+value)}
          {...inputProps}
          className={classNames(
            'remove-input-arrows text-center',
            {
              'naked-input': !inputEnabled,
              'w-12': !suffix,
              'w-7': suffix,
              'border-info-500 p-0 outline-info-200': inputEnabled,
            },
            inputProps.className
          )}
          boundaryErrorKey={boundaryErrorKey}
        />
        <span className="">{suffix}</span>
      </div>
      <StepButton
        onClick={() => onChange(+value + 1)}
        disabled={+value >= maxLimit}
        icon={PlusCircle}
      />
    </div>
  );
};

export default BStepperInput;
